import axios, { AxiosInstance } from "axios";
import { Security } from "../utils/Security";
import { store } from "../redux/store";
import { setSecureBlock } from "../redux/global/global";
import { random } from "lodash";


class Deferred {
  promise;
  reject: any;
  resolve: any;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

export let promiseSecureDefered: Deferred | null;

export const interceptorResponseSecureCaptcha = (axiosApiInstance: AxiosInstance) => {

  axiosApiInstance.interceptors.response.use(
    response => response,
    error => {

      const originalRequest = error.config;
      if (error.response?.status === 429) {

        const serverDate = (new Date(error.response.headers.date)).getTime();
        const clientDate = (new Date()).getTime();
        const differenceInSeconds = (serverDate - clientDate) / 1000;
        // console.log({serverDate, clientDate, differenceInSeconds})

        if (!promiseSecureDefered) {
          promiseSecureDefered = new Deferred();
          promiseSecureDefered.promise
            .finally(() => {
              promiseSecureDefered = null;
            });

        }
        store.dispatch(setSecureBlock(true));

        return promiseSecureDefered.promise
          .then(() => {

            // const timestampOld = Math.floor(Date.now() / 1000).toString();
            const clientDate = (new Date()).getTime() / 1000;
            const timestamp = Math.round(clientDate + differenceInSeconds).toString();
            // console.log({timestampOld, clientDate, differenceInSeconds,  timestamp});

            const h = Security.o(timestamp, 0, 1);
            const val = Security.o(random(99999).toString(), 4, 1);  // nahodna hodnota
            originalRequest.headers[`x-${h}`] = val;
            // originalRequest.withCredentials = true
            // document.cookie = `${h}=${val};`;
            // originalRequest.headers.Cookie  = `${h}=${val};`;
            // originalRequest.headers.Cookie  = document.cookie
            return axios(originalRequest);
          })
          .catch(error => {
            console.log("ERR secure ", error);
            return Promise.reject(error);
          });
      }

      return Promise.reject(error);
    }
  );
};
