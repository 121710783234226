import React, { useCallback, useEffect, useState } from "react";
import { iNews, iNewsReqParams } from "../../typings/news";
import NewsApi from "../../api/NewsApi";
import InfoBox from "../InfoBox/InfoBox";
import LoadingBox from "../LoadingBox/LoadingBox";
import {Button, Grid} from "@material-ui/core";
import { format } from "date-fns";
import { cs } from "date-fns/locale";
import { Link } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import globalStyles from "../../theme/global.styles";
import NewsListStyles from "./NewsList.styles"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

interface iNewsList {
  limit: number;
  hp?: boolean;
  showPaginator?: boolean;
}

const NewsList = ({ limit, hp = false, showPaginator = false }: iNewsList) => {
  const globalClasses = globalStyles();
  const newsListStylesClasses = NewsListStyles();
  const [data, setData] = useState<iNews>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [page, setPage] = useState(1);
  const [totalCount, setTotlaCount] = useState(0);
  const pagesCount = Math.ceil(totalCount / limit);

  const nextPageFromPagination = (e: any, page: number) => {
    fetchData(page);
  };

  const fetchData = useCallback((page: number) => {
    setPage(page);
    setLoading(true);
    const params: iNewsReqParams = {
      pageSize: limit,
      page: page,
      sortPublishedAsc: false
    };
    NewsApi.getNews(params)
      .then(({ data, results }) => {
        setData(data);
        setTotlaCount(results);
      })
      .catch(e => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData(1);
  }, []);

  if (error) {
    return <InfoBox text={"Chyba načtení aktualit"} />;
  }

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <>
      <Grid container spacing={3} style={{rowGap: 40}}>
        {data?.slice(0, limit).map((item, index) =>
          <Grid key={index} item md={4}>
            <article className={newsListStylesClasses.article}>
              <div style={{color: hp ? "#fff" : "#0c1c27"}}>
                <h3>
                  <Link to={`/aktuality/${item.Slug}`}
                        title={item.Title}
                        className={hp ? newsListStylesClasses.articleTitleHp : newsListStylesClasses.articleTitle}
                  >
                    {item.Title}
                  </Link>
                </h3>
                <small>
                  {format(new Date(item.PublishedUtc), "dd. MM. yyyy HH:mm", { locale: cs })}
                </small>
                <div dangerouslySetInnerHTML={{ __html: item.Perex ?? "" }}
                     className={hp ? newsListStylesClasses.articleTextHp : newsListStylesClasses.articleText}
                />
              </div>
              <Link to={`/aktuality/${item.Slug}`} title={item.Title}>
                <Button color={"primary"}
                        className={hp ? newsListStylesClasses.articleLinkHp : newsListStylesClasses.articleLink}
                >
                  Přečíst
                  <FontAwesomeIcon className={globalClasses.btnIcon} icon={faArrowRight}/>
                </Button>
              </Link>
            </article>
          </Grid>
        )}
      </Grid>
      {showPaginator &&
        <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
          <Pagination page={page} onChange={nextPageFromPagination} count={pagesCount} />
        </div>
      }
    </>
  );
};


export default NewsList;
