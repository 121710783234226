import React from "react";
import { Badge } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectFollowedContracts } from "../../../redux/user/selectors";
import useStyles from "./DesktopNavigation.styles";


const BadgeFollowedContracts = () => {
  const classes = useStyles();
  const followedContracts = useSelector(selectFollowedContracts);

  return (
    <Badge badgeContent={followedContracts?.length} color="primary" showZero={false} className={classes.badge}></Badge>
  );
};

export default BadgeFollowedContracts;