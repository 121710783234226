import { AxiosInstance } from "axios";
// import keycloak from "../utils/keycloak";
import { tokensGetToken } from "../utils/tokens";


export const interceptorRequestAuthToken = (axiosApiInstance: AxiosInstance) => {

  axiosApiInstance.interceptors.request.use(config => {
      // const token = keycloak.token;
      const token = tokensGetToken();

      if (token && config.headers.get("Authorization") !== "none") {
        config.headers.set("Authorization", `Bearer ${token}`)
      }
      return config;
    }
  );
};
