import { useEffect, useState } from "react";
import { add, format, isAfter } from "date-fns";
import { Tag } from "../typings/contract";
import useLocalStorage from "./useLocalStorage";
import NormalizerApi from "../api/NormalizerApi";
import packageJson from "../../package.json";
const PACKAGE_VERSION = packageJson.version;

interface ContractTagsReturnProps {
  isLoading: boolean;
  isError: boolean;
  tags: Array<Tag>;
  expiryDate: string;
}

const useContractTags = (): ContractTagsReturnProps => {
  const currentTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
  const [isError, setIsError] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tagsExpiration, setTagsExpiration] = useLocalStorage<string>("tagsExpiration" + PACKAGE_VERSION, currentTime);
  const [localStorageTags, setLocalStorageTags] = useLocalStorage<Array<Tag>>("tags" + PACKAGE_VERSION, []);

  useEffect(() => {
    const fetchTags = async () => {
      if (!isLoading) setIsLoading(true);
      try {
        const tags = await NormalizerApi.getTagsList();
        setLocalStorageTags(tags);
        setTagsExpiration(add(new Date(currentTime), { hours: 12 }));
      } catch (e) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (!Boolean(localStorageTags.length) || isAfter(new Date(), new Date(tagsExpiration))) {
      fetchTags();
    }
  }, []);

  return { isLoading, isError, tags: localStorageTags, expiryDate: tagsExpiration };
};

export default useContractTags;
