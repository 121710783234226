import { makeStyles } from "@material-ui/core";
import { theme } from "../../../theme/theme";

const ConfirmationModalStyles = makeStyles(() => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    borderRadius: "0.5rem",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    right: 0,
    width: "60%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: "initial",
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-34%, -50%)",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      transform: "translate(-6%, -50%)"
    }
  },
  typographyContainer: {
    paddingTop: 10
  },
  icon: {
    paddingBottom: 10
  },
  title: {
    fontWeight: 600,
    paddingBottom: 10
  },
  subtitle: {
    paddingBottom: 30
  },
  actionButtonContainer: {
    display: "flex",
    width: "50%",
    alignSelf: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  confirmActionButton: {
    width: 120,
    marginLeft: 5
  }
}));

export default ConfirmationModalStyles;
