import { useLocation } from "react-router-dom";
import { gaPageView } from "../utils/ga";
import { setMetaTags } from "../utils/window";

export const usePageViewMeta = () => {
  const location = useLocation();

  const setPageMeta = (title: string, desc?: string, keywords?: string[]) => {
    setMetaTags(title, desc, keywords);
    setTimeout(() => {
      gaPageView(location.pathname + location.search, title);
    }, 0);
  };
  return { setPageMeta };
};
