export const tokensSave = (tokens: any) => {
  localStorage.setItem("tokens", JSON.stringify(tokens));
};

export const tokensClear = () => {
  localStorage.setItem("tokens", "{}");
};

const tokensGet = () => {
  return JSON.parse(localStorage.getItem("tokens") || "{}")
}

export const tokensGetToken = () => {
  return tokensGet()?.token;
};

export const tokensGetIdToken = () => {
  return tokensGet()?.idToken;
};

export const tokensGetRefreshToken = () => {
  return tokensGet()?.refreshToken;
};
