import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer, { RootState } from "./root-reducer";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import thunkMiddleware from "redux-thunk";


const middlewares = [thunkMiddleware];

// if (process.env.NODE_ENV === "development") {
//   const { logger } = require(`redux-logger`);
//   middlewares.push(logger as any);
// }

const enhancer =
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares);

export const store = createStore(rootReducer, enhancer);

export type Store = typeof store;


export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>
