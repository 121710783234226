import queryString from "query-string";

import { history } from "./window";

export const addQueryParam = (key: string, value: any): string | null => {
  const parsedQuery = queryString.parse(window.location.search);
  if (parsedQuery[key] !== value) {
    // console.log('params not same key value ', key , value,  " -> add ");
    parsedQuery[key] = value;
    return queryString.stringify(parsedQuery);
  } else {
    // console.log('params has same key value ', key , value,  " -> nothing");
    return null;
  }
};

export const removeQueryParam = (key: string): string | null => {
  const parsedQuery = new URLSearchParams(window.location.search);
  if (parsedQuery.has(key)) {
    // console.log('params has key', key , " -> to delete");
    parsedQuery.delete(key);
    return parsedQuery.toString();
  } else {
    // console.log('params not has key', key , " -> nothing");
    return null;
  }
};

export const addQueryParamToUrl = (key: string, value: any): void => {
  const newParams = addQueryParam(key, value);
  // console.log('params after add ', newParams);
  if (newParams !== null) {
    // console.log('push');
    history.push({ search: newParams });
  }
};

export const removeQueryParamToUrl = (key: string): void => {
  const newParams = removeQueryParam(key);
  // console.log('params after remove', newParams);
  if (newParams !== null) {
    // console.log('push');
    history.push({ search: newParams });
  }
};

export const arrayToQueryParams = (array: Array<string | number | boolean>, name: string): string => {
  const string: Array<string> = [];

  array.map((value: string | number | boolean) =>
    string.push(`${encodeURIComponent(name)}[]=${encodeURIComponent(value)}`)
  );

  return string.join("&");
};
