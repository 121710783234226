import React from "react";
import useContractTags from "../../hooks/useContractTags";
import ContractSearcher from "./ContractSearcher";

export interface ContractResearcherStyles {
  wrapper: string;
  input: string;
  innerInput: string;
  searchButton: string;
  popUpList?: string;
}

interface ContractSearcherContainerProps {
  value?: string;
  tagValue?: Array<string>;
  onInputChange?: (phrase: string) => void;
  onTagsChange?: (tags: Array<string>) => void;
  withSubmitButton?: boolean;
  withContractSearch?: boolean;
  fullWidth?: boolean;
  customStyle?: ContractResearcherStyles;
  maxTagAmount?: number;
  searchContractsOnEmptyInput?: boolean;
}

const ContractSearcherContainer: React.FC<ContractSearcherContainerProps> = ({
  value,
  tagValue,
  onInputChange,
  onTagsChange,
  withSubmitButton = true,
  withContractSearch = true,
  fullWidth,
  customStyle,
  maxTagAmount,
  searchContractsOnEmptyInput = true
}) => {
  const tags = useContractTags();

  return (
    <ContractSearcher
      value={value}
      tagValue={tagValue}
      tagsLoading={tags.isLoading}
      tags={tags.tags}
      onInputChange={onInputChange}
      onTagsChange={onTagsChange}
      withSubmitButton={withSubmitButton}
      withContractSearch={withContractSearch}
      customStyle={customStyle}
      fullWidth={fullWidth}
      maxTagAmount={maxTagAmount}
      searchContractsOnEmptyInput={searchContractsOnEmptyInput}
    />
  );
};

export default ContractSearcherContainer;
