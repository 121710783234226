import ReactGA from "react-ga4";
import SubscriptionPlan, { SubscriptionPrice } from "../typings/subscriptionPlan";
import { isCokieConsentAccept } from "./cookieConsent";

const TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE || "";

export const gaInit = (forceCookieConsentAccept?: boolean) => {
  const cookieConsentAccept = forceCookieConsentAccept || isCokieConsentAccept();

  ReactGA.initialize(TRACKING_CODE || "0", {
    testMode: TRACKING_CODE ? false : true,
    gaOptions: {
      cookieDomain: "auto",
      cookieFlags: "SameSite=None;Secure",
      storage: cookieConsentAccept ? undefined : "none",
      storeGac: cookieConsentAccept ? true : false
    }
  });
};

export const gaAcceptCookie = () =>
  gaInit(true);

export const gaSetPage = (path: string) => ReactGA.set({ page: path }); // Update the user's current page

export const gaPageView = (path: string, title: string) =>
  ReactGA.send({ hitType: "pageview", page: path, title: title });
export const gaSearchContract = (path: string, searchPhrase: string, tags?: string[]) =>
  ReactGA.send({
    hitType: "pageview",
    page: `${path}?q=${searchPhrase}&c=zakázka${(tags && tags.length) ? `&tag=${tags.join(",")}` : ""}`
  });


export const gaSearchSubmitter = (path: string, searchPhrase: string) =>
  ReactGA.send({ hitType: "pageview", page: `${path}?q=${searchPhrase}&c=zadavatel` });

export const gaSetUserData = (userData: any | null) => ReactGA.set(userData);

/*
Cdd
 */
export const gaEventClickCddLogin = () =>
  ReactGA.event({
    action: "click",
    category: "cdd",
    label: "CDD Login"
  });

export const gaEventClickCddRegistration = () =>
  ReactGA.event({
    action: "click",
    category: "cdd",
    label: "CDD Registration"
  });


export const gaEventAfterCddLogin = () =>
  ReactGA.event({
    action: "login",
    category: "cdd",
    label: "CDD Login done"
  });

export const gaEventClickCddLogout = () =>
  ReactGA.event({
    action: "click",
    category: "cdd",
    label: "CDD Logout"
  });

/*
EventFilter
 */
export const gaEventFilterAdd = () =>
  ReactGA.event({
    action: "submit",
    category: "filter",
    label: "Filter add"
  });

export const gaEventFilterUpdate = () =>
  ReactGA.event({
    action: "submit",
    category: "filter",
    label: "Filter update"
  });

export const gaEventFilterShow = () =>
  ReactGA.event({
    action: "click",
    category: "filter",
    label: "Filter show submissions"
  });

export const gaEventFilterDelete = () =>
  ReactGA.event({
    action: "submit",
    category: "filter",
    label: "Filter delete"
  });

/*
Tracking
 */
export const gaEventTrackOn = () =>
  ReactGA.event({
    action: "click",
    category: "submission",
    label: "Tracking ON"
  });

export const gaEventTrackOff = () =>
  ReactGA.event({
    action: "click",
    category: "submission",
    label: "Tracking OFF"
  });

/*
E-Shop
 */

export const gaEventBeginCheckout = (price: SubscriptionPrice, plan: SubscriptionPlan) =>
  ReactGA.event({
    action: "begin_checkout",
    category: "eshop",
    label: `Buy ${plan}`,
    value: price
  });

export const gaEventViewDetail = (contractId: string, contractName: string, listName: string) =>
  ReactGA.event({
    action: "view_item",
    category: "view",
    label: `${listName}:${contractId} ${contractName}`
  });

export const gaEventSearch = (searchTerm: string, searchType: string, tags?: string[]) =>
  ReactGA.event({
    action: "view_search_results",
    category: "view",
    label: `${searchType}:${searchTerm}:${(tags && tags.length) ? tags.join(",") : ""}`
  });

export const gaEventOpenTags = () =>
  ReactGA.event({
    action: "open",
    category: "tags",
    label: "Open Tags"
  });

export const gaEventClickTag = (tag: string) =>
  ReactGA.event({
    action: "click",
    category: "tag",
    label: tag
  });

export const gaEventSelectTag = (tag: string) =>
  ReactGA.event({
    action: "select",
    category: "tag",
    label: tag
  });
