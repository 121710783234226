import axios from "axios";
import { interceptorRequestAuthToken } from "./interceptorRequestAuthToken";
import { interceptorResponseAuthToken } from "./interceptorResponseAuthToken";

/**
 * API instance pro CDD
 * Používá se pro získání informací o uživateli
 */
class CddApi {

  api = axios.create({
    baseURL: process.env.REACT_APP_CDD_ADMIN_URL
  });

  constructor() {
    interceptorRequestAuthToken(this.api);
    interceptorResponseAuthToken(this.api);
  }

  getCddInformation = () =>
    this.api.get("cdd-administrace/prihlasenyUzivatel")
      .then(res => res.data);

}

export default new CddApi();
