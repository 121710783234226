import React from "react";
import {Link} from "react-router-dom";
import { Button, Card, CardContent, Container, Grid, Typography } from "@material-ui/core";
import globalStyles from "../../../../theme/global.styles";
import useStyles from "../../Home.styles";
import CategoryListStyles from "./CategoryList.styles"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import CategoryPicker from "../CategoryPicker/CategoryPicker";

const categoryList = [
  {
    text: "Architekti a stavebně-technické služby",
    link: "/verejne-zakazky?phrase=%23architektistavba"
  },
  {
    text: "IT technologie",
    link: "/verejne-zakazky?phrase=%23ittechnologie"
  },
  {
    text: "Informační technologie - vývoj a služby",
    link: "/verejne-zakazky?phrase=%23vyvojit"
  },
  {
    text: "Doprava a cestování",
    link: "/verejne-zakazky?phrase=%23dopravacestovani"
  },
  {
    text: "Léky, léčiva, léčivé přípravky",
    link: "/verejne-zakazky?phrase=%23leky"
  },
  {
    text: "Nábytek",
    link: "/verejne-zakazky?phrase=%23nabytek"
  },
  {
    text: "Finance a pojištění",
    link: "/verejne-zakazky?phrase=%23financnipojisteni"
  },
  {
    text: "Paliva a energie",
    link: "/verejne-zakazky?phrase=%23palivaenergie"
  }
]

console.log("categoryList", categoryList.map((item) => (item.text)))

const CategoryList: React.FC = () => {
  const globalClasses = globalStyles();
  const classes = useStyles();
  const categoryListClasses = CategoryListStyles();

  return (
      <div className={categoryListClasses.wrapper}>
        <Container maxWidth="lg">
          <h2 className={classes.hpTitle}>Kategorie veřejných zakázek</h2>

          <Grid container spacing={4}>
            {categoryList.map((item) => (
              <Grid item md={3} sm={6} xs={12}>
                <Link to={item.link} title={item.text}>
                  <Card className={categoryListClasses.card}>
                    <CardContent className={categoryListClasses.cardContent}>
                      <Typography variant="h3" className={categoryListClasses.title}>{item.text}</Typography>
                      <Button color={"primary"} className={globalClasses.btnTertiary + " " + categoryListClasses.linkText}>
                        Zobrazit více
                        <FontAwesomeIcon className={globalClasses.btnIcon} icon={faArrowRight}/>
                      </Button>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>

          <CategoryPicker />

          <hr className={classes.hr}/>

        </Container>
      </div>
  )
}

export default CategoryList;