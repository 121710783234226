enum ErrorResponseMessages {
  UUID_INVALID = "Neplatný identifikátor.",
  FILTER_DUPLICATE_NAME = "Zadaný název filtru již existuje.",
  FILTER_INVALID_CONTENT = "Při vytváření filtru došlo k chybě, zkuste to prosím později.",
  MAX_FILTER_AMOUNT_REACHED = "Byla dosažena maximální kvóta uložených filtrů stanovená předplatným.",
  MAX_FOLLOWED_CONTRACTS_AMOUNT_REACHED = "Byla dosažena maximální kvóta sledovaných zakázek stanovená předplatným.",
  INVALID_SEARCH = "Pro zahrnutí logiky vyhledávání musí klíčová slova obsahovat alespoň jedno slovo.",
  INVALID_PHRASE = "Klíčová slova obsahují neplatné znaky.",
  INVALID_SCHEMA = "Požadavek nemohl být proveden. Zkuste to prosím později, popřípadě kontaktujte podporu.",
  DUPLICATE_EMAIL = "E-mailová adresa Vašeho účtu již v našem systému existuje. Pravděpodobně jste se již do PoDo přihlašoval/a, použijte prosím, stejný účet, jako při prvním přihlášení. Pokud potíže s přihlášením přetrvávají, kontaktujte nás.",
  INVALID_PHRASE_QUERY = "Vaši vyhledávácí frázi nelze zpracovat - zkontrolujte si prosím použití uvozovek ve vaší frázi",
  INVALID_AUTH_SCHEMA = "Přihlášení selhalo, autentizační služba FEN.cz je patrně mimo provoz, zkuste to prosím za chvíli.",
  UNAUTHENTICATED = "Proběhlo odhlášení, přihlašte se prosím znovu.",
  GENERAL = "Vyskytla se chyba. Opakujte akci později.",
  NO_FILTER_NOTPHRASE = "K použití filtru s negativní frází potřebujete vyšší předplatné. Upravte zadani Vaseho filtru a negativni frazi odeberte.",

  CONTRACT_NOT_FOUND = "Zakázka nebyla nalezena",
  CONTRACT_NOT_INDEX = "Chyba vyhledaní zakázky - nenalezen index v ES",
  CONTRACT_ES_ERR = "Chyba vyhledaní zakázky - chyba v ES",
  CONTRACT_ES_MODEL = "Chyba vyhledaní zakázky - nepodařilo se sestavit model zakázky v ES",
  CONTRACT_ES_NULL = "Chyba vyhledaní zakázky - nepodařilo se získat zakázku v ES"

}

export const ErrorCodeMap = {
  1200: ErrorResponseMessages.INVALID_AUTH_SCHEMA,
  1700: ErrorResponseMessages.UUID_INVALID,
  1701: ErrorResponseMessages.UUID_INVALID,
  1702: ErrorResponseMessages.MAX_FILTER_AMOUNT_REACHED,
  1800: ErrorResponseMessages.INVALID_SCHEMA,
  1801: ErrorResponseMessages.INVALID_SCHEMA,
  1802: ErrorResponseMessages.INVALID_SCHEMA,
  1900: ErrorResponseMessages.INVALID_SCHEMA,
  1901: ErrorResponseMessages.INVALID_SCHEMA,
  1902: ErrorResponseMessages.INVALID_SCHEMA,
  1903: ErrorResponseMessages.INVALID_SCHEMA,
  1904: ErrorResponseMessages.INVALID_SCHEMA,
  1905: ErrorResponseMessages.INVALID_PHRASE,
  1906: ErrorResponseMessages.INVALID_SCHEMA,
  1907: ErrorResponseMessages.INVALID_SCHEMA,
  1908: ErrorResponseMessages.INVALID_SCHEMA,
  2500: ErrorResponseMessages.MAX_FOLLOWED_CONTRACTS_AMOUNT_REACHED,
  1913: ErrorResponseMessages.NO_FILTER_NOTPHRASE
};

export const ErrorContractMap = {
  1806: ErrorResponseMessages.CONTRACT_NOT_FOUND,
  1907: ErrorResponseMessages.CONTRACT_NOT_INDEX,
  1912: ErrorResponseMessages.CONTRACT_ES_ERR,
  1809: ErrorResponseMessages.CONTRACT_ES_MODEL,
  1909: ErrorResponseMessages.CONTRACT_ES_NULL
};


export default ErrorResponseMessages;

// case FILTER_USER_NOT_FOUND_BY_ID = 1700;
// case FILTER_NOT_FOUND_BY_ID = 1701;
// case FILTER_COUNT_LIMIT_REACHED = 1702;

// case CONTRACT_SEARCH_DATA_TRANSFORMER_OBJECT_NOT_SUPPORTED = 1800;
// case CONTRACT_SEARCH_DATA_TRANSFORMER_MISSING_VALID_CONTEXT_URI = 1801;
// case CONTRACT_SEARCH_DATA_TRANSFORMER_CAN_NOT_PARSE_URI = 1802;

// case ELASTICSEARCH_RANGE_FILTER_INVALID_CONDITION = 1900;
// case ELASTICSEARCH_RANGE_FILTER_INVALID_BETWEEN_FORMAT = 1901;
// case ELASTICSEARCH_INVALID_FILTER_DEFINITION = 1902;
// case ELASTICSEARCH_PHRASE_FILTER_MISSING_ARGUMENT_FIELDS = 1903;
// case ELASTICSEARCH_QUERY_BUILDER_BUILDING_QUERY_STRING_FAILED = 1904;
// case ELASTICSEARCH_QUERY_BUILDER_PARSING_PHRASE_FAILED = 1905;
// case ELASTICSEARCH_QUERY_BUILDER_CPV_CODE_PREG_MATCH_FAILED = 1906;
// case ELASTICSEARCH_INDEX_NOT_FOUND = 1907;
// case ELASTICSEARCH_NO_NODES_AVAILABLE = 1908;

// case APP_PARAMETER_BAG_NO_STRING = 1000;
// case APP_PARAMETER_BAG_NO_BOOL = 1001;
// case APP_PARAMETER_BAG_NO_INT = 1002;
// case APP_PARAMETER_BAG_NO_ARRAY = 1003;
// case AUTH_NO_API_KEY = 1100;
// case AUTH_API_KEY_NOT_EXISTS = 1101;
// case AUTH_API_KEY_NOT_VALID = 1102;
// case AUTH_TOKEN_EXCEPTION = 1200;
// case ISSUER_NOT_EXISTS = 1300;
// case ISSUER_LOAD_JWK_SET_CACHE_FAILED = 1301;
// case ISSUER_LOAD_JWK_SET_FAILED = 1302;
// case USER_PROVIDER_ISSUER_NOT_EXISTS = 1400;
// case USER_PROVIDER_NO_EMAIL = 1401;
// case DEVICE_COUNT_LIMIT = 1500;
// case DEVICE_NO_RIGHTS = 1501;
// case DEVICE_GET_COUNT_FAILED = 1502;
// case DEVICE_GET_COUNT_NOT_NUMERIC = 1503;
// case USER_NOT_FOUND_BY_CDD_ID = 1600;

