import { makeStyles } from "@material-ui/core";
import { COLORS, theme } from "../../theme/theme";

const ContractSearcherStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center"
    },
    position: "relative",
    margin: "0 auto",
    justifyContent: "center",
    gap: 8
  },
  tagWrapper: {
    marginLeft: 5,
    display: "flex",
    rowGap: 2,
    columnGap: 5
  },
  input: {
    color: "#707070",
    height: 60,
    padding: 0,
    background: "rgba(255, 255, 255, 0.85)",
    borderRadius: 0,
    marginBottom: 10,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      minWidth: 660
    }
  },
  innerInput: {
    height: "100%",
    padding: "11px 15px"
  },
  searchButton: {
    minWidth: 230,
    height: 60,
    textAlign: "center",
    fontWeight: "bold",
    color: "#fff",
    background: COLORS.RED,
    borderRadius: 0,
    boxShadow: "none",
    "&:hover": {
      background: COLORS.DARKRED,
      outline: "none",
      boxShadow: "none"
    }
  },
  btnSearchIcon: {
    fontSize: 18,
    marginBottom: 2,
    marginLeft: 15
  },
  popUpList: {
    maxHeight: 250,
    margin: 0,
    padding: 0,
    listStyle: "none",
    background: "#fff",
    position: "absolute",
    top: 60,
    width: "100%",
    borderRadius: 0,
    left: 0,
    [theme.breakpoints.up("md")]: {
      width: 660,
      left: 7,
      top: "100%",
    },
    overflowY: "auto",
    border: "1px solid",
    color: "#000",
    borderColor: "rgba(0, 0, 0, 0.23)",
    zIndex: 3,
    textAlign: "left"
  },
  listItem: {
    width: "100%",
    height: 40,
    padding: "10px 15px",
    margin: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      background: "#ececec",
      cursor: "pointer"
    }
  },
  activeListItem: {
    width: "100%",
    padding: "10px 15px",
    margin: 0,
    background: "#ececec"
  },
  noItem: {
    width: "100%",
    padding: "10px 15px",
    margin: 0
  }
}));

export default ContractSearcherStyles;
