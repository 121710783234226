import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageWrap from "../../components/PageWrap/PageWrap";
import { gaPageView } from "../../utils/ga";

const ServiceUnavailableErrorPage = () => {
  const location = useLocation();
  const errorTitle = "503 služba je nedostupná";

  useEffect(() => {
    gaPageView(location.pathname + location.search, errorTitle);
  }, []);

  return (
    <PageWrap breadcrumbNavigation={[{ label: errorTitle }]} title={errorTitle}>
      Požadovaná data nejsou nyní přístupná. Akci opakujte později.
    </PageWrap>
  );
};

export default ServiceUnavailableErrorPage;
