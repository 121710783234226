import React, { useEffect } from "react";
import { useLocation } from "react-router";
import PageWrap from "../../components/PageWrap/PageWrap";
import { COLORS } from "../../theme/theme";
import { gaPageView } from "../../utils/ga";
import { useHandleLogin } from "../../hooks/useHandleLogin";

const UnauthorizedErrorPage = () => {
  const location = useLocation();
  const { handleLogin } = useHandleLogin();

  const errorTitle = "401 neoprávněný přístup";

  useEffect(() => {
    gaPageView(location.pathname + location.search, errorTitle);
  }, []);

  return (
    <PageWrap breadcrumbNavigation={[{ label: errorTitle }]} title={errorTitle}>
      <div>
        K akci se prosím{" "}
        <span
          style={{ color: COLORS.GOLD, cursor: "pointer", fontWeight: "bold" }}
          onClick={handleLogin}
        >
          přihlaste
        </span>{" "}
        prostřednictvím Centrální databáze dodavatelů (CDD).
      </div>
    </PageWrap>
  );
};

export default UnauthorizedErrorPage;
