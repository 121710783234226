import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import useStyles from "./CookiePanel.styles";
import { gaAcceptCookie } from "../../utils/ga";
import { setCookieConsentAccept, setCookieConsentForbit, isSetCookieConsent } from "../../utils/cookieConsent";

const CookiePanel = () => {
  const classes = useStyles();
  const [close, setClose] = useState(false);
  const isSetConsent = isSetCookieConsent();

  const onClickAccept = () => {
    gaAcceptCookie();
    setCookieConsentAccept();
    setClose(true);
  };

  const onClickForbit = () => {
    setCookieConsentForbit();
    setClose(true);
  };

  if (close || isSetConsent) {
    return null;
  }

  return (
    <div id="cookie-panel" className={classes.box}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.text}>
          Portál používá NUTNÉ soubory cookie, které jsou naprosto nezbytné pro správné fungování webu.
          Anonymně zajišťují základní funkce a vypnout je nelze.
          Analytické soubory cookie pomáhají zlepšovat weby pro návštěvníky. Ty zakázat lze.
        </div>

        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={onClickForbit}
        >
          Zakázat
        </Button>

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onClickAccept}
        >
          Povolit
        </Button>

      </Container>
    </div>
  );
};

export default CookiePanel;