import { makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../theme/theme";

const LockedOverlayWrapStyles = makeStyles(() => ({
  overlaybg: {
    position: "relative",
    "&::after": {
      display: "block",
      content: "\" \"",
      position: "absolute",
      background: "rgba(0,0,0,0.5)",
      left: -20,
      right: -20,
      top: -20,
      bottom: -20
    }
  },
  popup: {
    zIndex: 10,
    top: 300,
    right: "0",
    width: "50%",
    position: "absolute",
    transform: "translate(-49%, 0)",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      transform: "translate(-15%, 0)",
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 1 auto",
    padding: theme.spacing(2, 4, 3)
  },
  lockedButton: {
    border: `1px solid ${COLORS.GOLD} !important`,
    color: `${COLORS.GOLD} !important`,
    maxWidth: 200,
    margin: "0 auto",
    marginTop: "1em"

  },
  textTitle: {
    fontWeight: 800,
    color: COLORS.GOLD,
    marginBottom: 20
  },
  textBody: {
    color: "#707070"
  },
  button: {
    fontWeight: 500,
    backgroundColor: COLORS.GOLD,
    "&:hover": {
      backgroundColor: "#b19b81"
    }
  }
}));

export default LockedOverlayWrapStyles;
