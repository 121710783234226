import axios from "axios";
import { iNews, iNewsItem, iNewsReqParams } from "../typings/news";

const NEWS_API_LABELS = ["PoDo"];

class NewsApi {

  api = axios.create({
    baseURL: process.env.REACT_APP_NEWS_URL
  });

  getNews = (params: iNewsReqParams) =>
    this.api.get<iNews>(`/api/blog`, {
      params: {
        labels: NEWS_API_LABELS,
        ...params
      }
    })
      .then(res => ({
        data: res.data,
        results: parseInt(res.headers["x-total-count"])
      }));


  getNewsDetail = (slug: string) =>
    this.api.get<iNewsItem>(`/api/blog/${slug}`)
      .then(res => res.data);

}

export default new NewsApi();
