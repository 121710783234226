import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IContract } from "../../typings/contract";
import { IFilter } from "../../typings/filters";

interface IState {
  filters: Array<IFilter>;
  detail: IFilter | null;
  filterContracts: {
    data: Array<IContract>;
    results: number;
    page: number;
    size: number;
  };
  status: {
    loading: boolean;
    fetchLoading: boolean;
    success: boolean;
    successMessage: string;
    filterContractsLoading: boolean;
    error: boolean;
    errorMessage: string;
    omit: boolean;
  };
  overwriteFilterModalOpen: boolean;
}

export const NEWFILTER: IFilter = {
  id: undefined,
  name: "",
  phrase: "",
  notPhrase: "",
  cpvCodes: [],
  submitterName: null,
  submitterId: null,
  executionPlaces: [],
  tags: [],
  beginsAtFrom: null,
  beginsAtTo: null,
  publishedAtFrom: null,
  publishedAtTo: null,
  offersDeadlineAtFrom: null,
  offersDeadlineAtTo: null,
  priceMin: null,
  priceMax: null,
  includeWithoutPrice: null,
  profileNumber: null,
  statuses: null,
  kinds: null,
  includeWithoutCpvCodes: false,
  includeSubCpvCodes: false,
  includeWithoutExecutionPlaces: false,
  full: true,
  alternativeSearch: true
};

const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    filters: [],
    detail: null,
    filterContracts: {
      data: [],
      results: 0,
      page: 1,
      size: 20
    },
    status: {
      loading: false,
      fetchLoading: false,
      success: false,
      successMessage: "",
      filterContractsLoading: false,
      error: false,
      errorMessage: "",
      omit: false
    },
    overwriteFilterModalOpen: false
  } as IState,
  reducers: {
    saveFilterStart: (state) => {
      state.status.loading = true;
    },
    saveFilterSuccess: (state) => {
      const { status } = state;
      status.loading = false;
      status.success = true;
    },
    saveFilterFailure: (state) => {
      const { status } = state;
      status.loading = false;
      status.error = true;
    },
    deleteFilterStart: (state) => {
      state.status.loading = true;
    },
    deleteFilterSuccess: (state, action: PayloadAction<string>) => {
      const { status } = state;

      state.filters = state.filters.filter((filter) => filter.id !== action.payload);
      status.success = true;
      status.successMessage = "Filtr smazán úspěšně.";
      status.loading = false;
    },
    deleteFilterFailure: (state) => {
      const { status } = state;
      status.error = true;
      status.errorMessage = "Při mazání filtru se vyskytla chyba";
      status.loading = false;
    },
    fetchUserFilterDetailStart: (state) => {
      state.status.loading = true;
    },
    fetchUserFilterDetailSuccess: (state, { payload }: PayloadAction<IFilter>) => {
      state.detail = payload;
      state.status.loading = false;
    },
    fetchUserFilterDetailFailure: (state) => {
      state.status.loading = false;
      state.status.error = true;
    },
    fetchUserFiltersStart: (state) => {
      state.status.fetchLoading = true;
    },
    fetchUserFiltersSuccess: (state, action: PayloadAction<IFilter[]>) => {
      state.status.fetchLoading = false;
      state.filters = action.payload;
    },
    fetchUserFiltersFailure: (state, action: PayloadAction<string>) => {
      const { status } = state;
      status.fetchLoading = false;
      status.error = true;
      status.errorMessage = action.payload;
    },
    fetchFilterContractsStart: (state, action: PayloadAction<{ omit?: boolean }>) => {
      const { status } = state;
      status.filterContractsLoading = true;
      status.omit = action.payload?.omit || false;
    },
    fetchFilterContractsSuccess: (state, action: PayloadAction<{ contracts: Array<IContract>; results: number }>) => {
      const { status } = state;
      status.filterContractsLoading = false;
      state.filterContracts.data = action.payload.contracts;
      state.filterContracts.results = action.payload.results;
      status.omit = false;
    },
    fetchFilterContractsFailure: (state, action: PayloadAction<string>) => {
      const { status } = state;
      status.filterContractsLoading = false;
      status.error = true;
      status.errorMessage = action.payload;
      status.omit = false;
    },
    setFilterContractsPage: (state, action: PayloadAction<number>) => {
      state.filterContracts.page = action.payload;
    },
    toggleNotificationStart: (state) => {
      state.status.loading = true;
    },
    toggleNotificationSuccess: (state) => {
      const { status } = state;
      status.loading = false;
      status.success = true;
    },
    toggleNotificationFailure: (state) => {
      const { status } = state;
      status.loading = false;
      status.error = true;
    },
    setOverwriteFilterModal: (state, action: PayloadAction<boolean>) => {
      state.overwriteFilterModalOpen = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.filterContracts.size = action.payload;
    },
    clearFilterStatus: (state) => {
      const { status } = state;
      status.errorMessage = "";
      status.loading = false;
      status.fetchLoading = false;
      status.error = false;
      status.success = false;
      status.successMessage = "";
    },
    locationChangeFilters: (state) => {
      state.detail = null;
      state.filterContracts.data = [];
      state.filterContracts.results = 0;
    }
  }
});

const { actions, reducer } = filtersSlice;
export const {
  saveFilterStart,
  saveFilterSuccess,
  saveFilterFailure,
  deleteFilterStart,
  deleteFilterSuccess,
  deleteFilterFailure,
  fetchUserFilterDetailStart,
  fetchUserFilterDetailSuccess,
  fetchUserFilterDetailFailure,
  fetchUserFiltersStart,
  fetchUserFiltersSuccess,
  fetchUserFiltersFailure,
  fetchFilterContractsStart,
  fetchFilterContractsSuccess,
  fetchFilterContractsFailure,
  setFilterContractsPage,
  toggleNotificationStart,
  toggleNotificationSuccess,
  toggleNotificationFailure,
  setOverwriteFilterModal,
  setRowsPerPage,
  clearFilterStatus,
  locationChangeFilters
} = actions;
export default reducer;
