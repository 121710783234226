import { fetchStatisticsFailure, fetchStatisticsStart, fetchStatisticsSuccess } from "./global";
import { AppThunk } from "../store";
import { toast } from "react-toastify";
import ErrorResponseMessages from "../../typings/errorResponse";
import PodoApi from "../../api/PodoApi";


export const fetchStatistics = (): AppThunk => async (dispatch) => {
  dispatch(fetchStatisticsStart());
  try {
    const data = await PodoApi.getContractStatistics();

    dispatch(fetchStatisticsSuccess(data));
  } catch (e) {
    dispatch(fetchStatisticsFailure());
    toast.error(ErrorResponseMessages.GENERAL);
  }
};