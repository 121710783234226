import {
  addFollowedContract,
  clearUserStatus,
  deleteFollowedContract,
  logInFailure,
  logInStart,
  logInSuccess,
  logOut,
  setFollowedContracts,
  setFollowedContractsFailure,
  setFollowedContractsStart,
  setSubscriptionLimit,
  setUser,
  updateUserSettingsFailure,
  updateUserSettingsStart,
  updateUserSettingsSuccess
} from "./user";
import { selectUser } from "./selectors";
import { toast } from "react-toastify";
import { AppThunk } from "../store";
import { parseErrorSync } from "../../utils/api";
import { IUser } from "../../typings/user";
import PodoApi from "../../api/PodoApi";
import CddApi from "../../api/CddApi";
import { transformCddInformation } from "../../utils/user";
import { gaEventClickCddLogout, gaEventTrackOff, gaEventTrackOn } from "../../utils/ga";
import { setFilter } from "../global/global";
import { NEWFILTER } from "../filters/filters";
import { tokensClear } from "../../utils/tokens";
import keycloak from "../../utils/keycloak";


export const updateUserSettings = (data: Partial<IUser>): AppThunk => async (dispatch, getState) => {
  dispatch(updateUserSettingsStart());
  try {
    const user = selectUser(getState());
    await PodoApi.editUserSettings(user!.id, data);
    dispatch(updateUserSettingsSuccess());
    dispatch(setUser({ ...user, ...data } as IUser));
    toast.success("Nastavení úspěšně uloženo", {
      onClose: () => dispatch(clearUserStatus())
    });
  } catch (e: any) {
    const message = parseErrorSync(e);
    dispatch(updateUserSettingsFailure());
    toast.error(message, {
      onClose: () => dispatch(clearUserStatus())
    });
  }
};

export const logIn = (): AppThunk => async (dispatch) => {
  dispatch(logInStart());
  try {
    const data = await PodoApi.authenticateUser();
    const cddInformation = await CddApi.getCddInformation();
    const transformedInformation = transformCddInformation(cddInformation);

    dispatch(logInSuccess({ user: data, cddInformation: transformedInformation }));

    dispatch(loadFollowedContracts());

    PodoApi.getSubscriptionLimit().then(limit => {
      dispatch(setSubscriptionLimit(limit));
    });


  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(logInFailure(message));
    toast.error(message);
  }
};

export const logOutAction = (msg?: string): AppThunk => async (dispatch) => {
  gaEventClickCddLogout();
  dispatch(logOut());
  dispatch(setFilter(NEWFILTER));
  tokensClear();
  keycloak.clearToken();
  toast.info(`Uživatel byl odhlášen. ${msg || ""}`);
};


export const loadFollowedContracts = (): AppThunk => async (dispatch) => {
  try {
    const data = await PodoApi.getFollowingContracts();
    const contractIds = data
      .filter((d: any) => d.contract)
      .map((d: any) => ({
        id: d.id,
        contractId: d.contract.id
      }));
    dispatch(setFollowedContracts(contractIds));

  } catch (e: unknown) {
    const message = parseErrorSync(e);
    toast.error(message);
  }
};

export const addFollowedContracts = (followContractId: string): AppThunk => async (dispatch, getState) => {
  dispatch(setFollowedContractsStart());
  try {
    const response = await PodoApi.addFollowingContract(followContractId);
    dispatch(addFollowedContract({
      id: response.id,
      contractId: response.contractId
    }));
    toast.success(`Zakázka byla zařazena mezi sledované.`);
    gaEventTrackOn();

  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(setFollowedContractsFailure(message));
    toast.error(message);
  }
};


export const deleteFollowedContracts = (followContractId: string): AppThunk => async (dispatch, getState) => {
  dispatch(setFollowedContractsStart());
  try {
    const followedContracts = getState().user.followedContracts;
    const followId = followedContracts.find(f => f.contractId === followContractId)!.id;
    await PodoApi.deleteFollowingContract(followId);
    dispatch(deleteFollowedContract(followContractId));
    toast.success(`Zakázka byla odebrána ze sledovaných.`);
    gaEventTrackOff();

  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(setFollowedContractsFailure(message));
    toast.error(message);
  }
};
