import { useSelector } from "react-redux";
import { selectUser } from "../redux/user/selectors";
import SubscriptionPlan from "../typings/subscriptionPlan";
import { IUser } from "../typings/user";

export const useUserRoles = () => {
  const user = useSelector(selectUser);
  const userRoles = getUserRoleFromLevel(user);

  return {
    user: user,
    isLoged: !!user,
    roles: userRoles,
    isRoleFree: userRoles.length === 1 && userRoles[0] === SubscriptionPlan.FREE,
    canAdvanceFilter: userRoles.includes(SubscriptionPlan.FREE),
    canEkom: userRoles.includes(SubscriptionPlan.FREE),
    canFilterNotif: userRoles.includes(SubscriptionPlan.PROFI),
    canFollowed: userRoles.includes(SubscriptionPlan.PROFI),
    cadContractDocs: userRoles.includes(SubscriptionPlan.PROFI),
    canEkomCreateMsg: userRoles.includes(SubscriptionPlan.PREMIUM),
    canFilterActiveContract: userRoles.includes(SubscriptionPlan.PREMIUM),
    canUnactiveContract: userRoles.includes(SubscriptionPlan.PREMIUM),
    canInstantNotifi: userRoles.includes(SubscriptionPlan.PREMIUM),
    canNegativeFilter: userRoles.includes(SubscriptionPlan.EXCELLENT)
  };
};


const getUserRoleFromLevel = (user: IUser | null) => {
  if (!user) {
    return [];
  }
  const level = user.activeSubscription?.level;
  const roles: string[] = [SubscriptionPlan.FREE];

  if (!level) {
    return roles;  // Pokud není úroveň, vrátí se jen role FREE
  }

  const subscriptionRolesMap = {
    [SubscriptionPlan.PROFI]: [SubscriptionPlan.PROFI],
    [SubscriptionPlan.PREMIUM]: [SubscriptionPlan.PROFI, SubscriptionPlan.PREMIUM],
    [SubscriptionPlan.EXCELLENT]: [SubscriptionPlan.PROFI, SubscriptionPlan.PREMIUM, SubscriptionPlan.EXCELLENT]
  };

  if (level in subscriptionRolesMap) {
    roles.push(...subscriptionRolesMap[level as keyof typeof subscriptionRolesMap]);
  }

  return roles;
};
