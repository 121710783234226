import { createSlice } from "@reduxjs/toolkit";


const INITIAL_STATE = {
  payment: {
    status: {
      processing: false,
      success: false,
      error: false
    }
  }
};


const eshopReducer = createSlice({
  initialState: INITIAL_STATE,
  name: "eshop",
  reducers: {
    beginPayment: (state) => {
      state.payment.status.processing = true;
    },
    // successfulPayment: (state) => {
    //   state.payment.status.processing = false;
    //   state.payment.status.success = true;
    // },
    failedPayment: (state) => {
      state.payment.status.processing = false;
      state.payment.status.error = true;
    }
    // checkUserPaymentStart: (state, action: PayloadAction<SubscriptionPlan>) => {
    //   state.payment.status.processing = !!action;
    // },
    // checkUserPaymentSuccess: (state) => {
    //   state.payment.status.processing = false;
    //   state.payment.status.success = true;
    // },
    // checkUserPaymentFailure: (state) => {
    //   state.payment.status.processing = false;
    //   state.payment.status.error = true;
    // },
    // clearPaymentStatus: (state) => {
    //   state.payment.status = {
    //     processing: false,
    //     success: false,
    //     error: false
    //   };
    // }
  }
});

const { reducer, actions } = eshopReducer;

export const {
  beginPayment,
  // successfulPayment,
  failedPayment
  // checkUserPaymentStart,
  // checkUserPaymentSuccess,
  // checkUserPaymentFailure,
  // clearPaymentStatus
} = actions;

export default reducer;
