import axios, { AxiosInstance } from "axios";
import keycloak from "../utils/keycloak";
import { redirectServiceUnavailable } from "../navigation/redirectError";
import { store } from "../redux/store";
import { logOutAction } from "../redux/user/actions";
import { addQueryParamToUrl } from "../utils/url";
import { toast } from "react-toastify";

export const logOutUser = () => {
  store.dispatch(logOutAction() as any);
  addQueryParamToUrl("logoutNoRefresh", "true");
  toast.dismiss();
  window.location.reload();
};


let refreshTokenPromise: Promise<any> | null; // this holds any in-progress token refresh requests
export const interceptorResponseAuthToken = (axiosApiInstance: AxiosInstance, logout = true) => {

  axiosApiInstance.interceptors.response.use(
    response => response,
    error => {

      if (error.response?.status === 503) {
        redirectServiceUnavailable();
        return Promise.reject(error);
      }

      const originalRequest = error.config;

      // Reject promise if usual error
      if (error.response?.status !== 401 || originalRequest._retry) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;

      originalRequest!.headers = { ...originalRequest!.headers };

      if (!refreshTokenPromise) {
        refreshTokenPromise = keycloak.updateToken(-1).catch(errorUpdate => {
          if (logout) {
            logOutUser();
          }
          return Promise.reject(error);
        })
          .finally(() => {
            refreshTokenPromise = null; // clear state
          });
      }

      return refreshTokenPromise.then(refreshed => {
        if (refreshed) {
          originalRequest.headers["Authorization"] = `Bearer ${keycloak.token}`;
          // axiosApiInstance.defaults.headers["Authorization"] = `Bearer ${keycloak.token}`;
          return axios(originalRequest);
        } else {
          return Promise.reject(error);
        }
      })

    }
  );
};
