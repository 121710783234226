export const redirectNotFound = () => {
  window.location.href = "/404-not-found?source=" + window.location.pathname;
  return null;
};

export const redirectForbidden = () => {
  window.location.href = "/403-forbidden?source=" + window.location.pathname;
  return null;
};

export const redirectServiceUnavailable = () => {
  window.location.href = "/503-service-unavailable?source=" + window.location.pathname;
  return null;
};

export const redirectUnauthorized = () => {
  window.location.href = "/401-unauthorized?source=" + window.location.pathname;
};
