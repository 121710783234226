import React from "react";
import { Badge } from "@material-ui/core";
import { useCountUnreadAll } from "@qcm/ekom-lib";
import { useUserHasToken } from "../../../hooks/useUserHasToken";
import useStyles from "./DesktopNavigation.styles";
import { useUserRoles } from "../../../hooks/useUserRoles";


const BadgeUnreadMsgsIn = ({ countUnread }: { countUnread: number }) => {
  const classes = useStyles();
  return (
    <Badge badgeContent={countUnread} color="primary" showZero={false} className={classes.badge}></Badge>
  );
};

const BageUnreadMsgLoadWrap = () => {
  const { countUnread } = useCountUnreadAll();
  return <BadgeUnreadMsgsIn countUnread={countUnread} />;
};

const BadgeUnreadMsgs = () => {
  const { canEkom: userCanEkom } = useUserRoles();
  const userHasToken = useUserHasToken();

  return (userCanEkom && userHasToken) ? <BageUnreadMsgLoadWrap /> : <BadgeUnreadMsgsIn countUnread={0} />;
};

export default BadgeUnreadMsgs;
