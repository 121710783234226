import PodoApi from "../api/PodoApi";

class SecureTokens {
  token?: string;
  expire?: Date;
  MINUTE_EXPIRE = 14;
  promise?: Promise<string>;

  getToken() {
    const now = new Date();
    if (this.token && this.expire && now < this.expire) {
      return this.token;
    }
  }

  setToken(token: string) {
    this.token = token;
    const now = new Date();
    this.expire = new Date(now.getTime() + this.MINUTE_EXPIRE * 60 * 1000);
  }

  fetchAlive() {
    if (this.promise) {
      return this.promise;
    }
    this.promise = PodoApi.alive().then(data => {
      this.setToken(data);
      return data;
    }).finally(() => {
      this.promise = undefined;
    });
    return this.promise;
  }

}

const ST = new SecureTokens();
export default ST;
