export const transformCddInformation = (cddInformation: any) => {
  const { informaceOUzivateli, korenovyDodavatel } = cddInformation;

  return {
    user: {
      firstName: informaceOUzivateli.krestniJmeno,
      lastName: informaceOUzivateli.prijmeni,
      cddId: informaceOUzivateli.id,
      email: informaceOUzivateli.kontaktniUdaje?.email
    },
    supplier: {
      name: korenovyDodavatel.nazev,
      cddId: korenovyDodavatel.id,
      ic: korenovyDodavatel.identifikacniUdaje?.ic
    }
  };
};
