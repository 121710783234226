import React, { useEffect } from "react";
import {Button, Card, CardContent, Grid, Typography} from "@material-ui/core";
import { AttachMoney, LibraryBooks, People, Search } from "@material-ui/icons";
import CountUp from "react-countup";
import globalStyles from "../../../../theme/global.styles";
import useStyles from "../../Home.styles";
import StatisticsStyles from "../../../../components/Statistics/Statistics.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/root-reducer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { fetchStatistics } from "../../../../redux/global/actions";
import { useInView } from "react-intersection-observer";
import { processData } from "../../../../utils/prepareStats"

const Statistics: React.FC = () => {
  const dispatch = useDispatch();
  const globalClasses = globalStyles();
  const classes = useStyles();
  const statisticsClasses = StatisticsStyles();
  const { loading, data } = useSelector((state: RootState) => state.global.statistics);

  useEffect(() => {
    dispatch(fetchStatistics());
  }, []);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  const preparedData = loading || !data ? {} : processData(data.overall);

  return (
      <div className={statisticsClasses.wrapper}>
        <div ref={ref}>
          {inView && <Grid container className={statisticsClasses.cardWrapper} spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardIcon}>
                    <LibraryBooks style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.textTitle}>
                    <CountUp end={loading || !data ? 0 : preparedData.contracts.value} duration={1} separator=" "/>
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    {loading || !data ? "" : preparedData.contracts.legend} zakázek
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardIcon}>
                    <AttachMoney style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.textTitle}>
                    <CountUp
                        end={loading || !data ? 0 : preparedData.moneyAmount.value}
                        duration={1}
                        separator=" "
                        decimals={2}
                        decimal=","
                    />
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    {loading || !data ? "" : preparedData.moneyAmount.legend} korun
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardIcon}>
                    <People style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.textTitle}>
                    <CountUp end={loading || !data ? 0 : preparedData.submitters.value} duration={1} separator=" "/>
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    {loading || !data ? "" : preparedData.submitters.legend} zadavatelů
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardIcon}>
                    <Search style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.textTitle}>
                    <CountUp end={loading || !data ? 0 : preparedData.activeContracts.value} duration={1} separator=" "/>
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    {loading || !data ? "" : preparedData.activeContracts.legend} aktivních zakázek
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>}

          <div className={classes.linkWrap} style={{paddingTop: 20, paddingBottom: 20}}>
            <Link to="/verejne-zakazky" title="Katalog veřejných zakázek">
              <Button className={globalClasses.btnSecondary}>
                Přejít do zakázek
                <FontAwesomeIcon className={globalClasses.btnIcon} icon={faArrowRight}/>
              </Button>
            </Link>
          </div>
        </div>
      </div>
  );
};

export default Statistics;
