import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Box, Typography } from "@material-ui/core";
import BreadcrumbNavigationStyles from "./BreadcrumbNavigation.styles";

interface BreadcrumbNavigationProps {
  breadcrumbNavigation?: Array<{ label: string; path?: string }>;
}

const BreadcrumbNavigation: React.FC<BreadcrumbNavigationProps> = ({ breadcrumbNavigation }) => {
  const classes = BreadcrumbNavigationStyles();

  if (breadcrumbNavigation) {
    breadcrumbNavigation = [{ label: "Portál Dodavatele", path: "/" }, ...breadcrumbNavigation];
  }

  // https://schema.org/BreadcrumbList
  const breadcrumbStructuredData = !breadcrumbNavigation ? {} : {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbNavigation.map((b, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "name": b.label,
      "item": b.path ? (window.location.protocol + "//" + window.location.host + b.path) : undefined
    }))
  };

  return (
    <Box className={classes.wrapper}>
      {breadcrumbNavigation &&
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbStructuredData)}
        </script>
      }
      <Typography variant="body1" className={classes.title}>
        Nacházíte se na:
      </Typography>{" "}
      <Breadcrumbs
        separator={<NavigateNextIcon style={{ fontSize: 17 }} />}
        aria-label="breadcrumb"
        style={{ fontSize: 12 }}
      >
        {breadcrumbNavigation?.map(({ label, path }, index) => {
          if (index + 1 === breadcrumbNavigation?.length) return <span key={index}>{label}</span>;

          return (
            <Link to={path || ""} key={label} style={{ color: "rgba(0, 0, 0, .75)" }} title={label}>
              {label}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbNavigation;
