import React, { ReactNode } from "react";

class ErrorBoundary extends React.Component<{ children?: ReactNode }, { hasError: boolean }> {
  constructor(props: { children?: ReactNode }) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {}

  render(): ReactNode | null | undefined {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <h1>Chyba. Prosím opakujte akci, popř. kontaktujte provozovatele.</h1>;
    }
    return children;
  }
}

export default ErrorBoundary;
