import { faArrowRight, faTags } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { Button, Container, Tooltip } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import ContractSearcherContainer from "../../components/ContractSearcher/ContractSearcher.container";
import { faKeyboard } from "@fortawesome/free-regular-svg-icons";
import { setFilter, setPhrase } from "../../redux/global/global";
import globalStyles from "../../theme/global.styles";
import useStyles from "./Home.styles";
import Statistics from "./components/Statistics/Statistics";
import { HelpOutlineRounded } from "@material-ui/icons";
import BasicSearchTooltip from "../../components/Tooltip/BasicSearchTooltip";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewsList from "../../components/News/NewsList";
import CategoryList from "./components/CategoryList/CategoryList";
import PodoArguments from "./components/PodoArguments/PodoArguments";
// import { INITIAL_STATE } from "../../redux/submitterCatalogue/submitterCatalogue";
// import { setRowsPerPage } from "../../redux/contractCatalogue/contractCatalogue";

const Home: React.FC = () => {
  const globalClasses = globalStyles();
  const classes = useStyles();
  const params = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.hash) {
      const id = params.hash.replace("#", "");

      document.getElementById(id)?.scrollIntoView();
    }
  });

  return (
    <div className={classes.hpWrap}>
      <div className={classes.topWrap}>
        <Container maxWidth="md">
          <div className={classes.introBlock}>
            <div className={classes.intro1}>Portál dodavatele</div>
            <h1 className={classes.intro2}>Všechny veřejné zakázky na jednom místě</h1>
            <hr className={classes.hr}/>
            <h3 className={classes.intro3}>
              Najděte si tu správnou
              <Tooltip title={<BasicSearchTooltip/>} enterTouchDelay={50} leaveTouchDelay={10000} interactive arrow>
                <HelpOutlineRounded style={{color: "rgb(118, 118, 118)", marginLeft: 5}}/>
              </Tooltip>
            </h3>
          </div>

          <div className={classes.introWrapper}>
            <div className={classes.introSectionWrapper}>
              <div className={classes.introTitle}>
                <FontAwesomeIcon className={classes.introTitleIcon} icon={faKeyboard}/> Vyhledávání s našeptávačem
              </div>
              <span className={classes.introSubTitle}>Napište normální text...</span>
            </div>
            <div className={classes.introSectionWrapper}>
              <div className={classes.introTitle}>
                <FontAwesomeIcon className={classes.introTitleIcon} icon={faTags}/> Hledat podle TAGů zakázky
              </div>
              <span className={classes.introSubTitle}>Začněte tímto symbolem: #</span>
            </div>
          </div>
          <ContractSearcherContainer
              onInputChange={(phrase) => {
                const phraseSplit = phrase.split(" ");
                const tags = phraseSplit
                    .filter((string) => string.startsWith("#"))
                    .map((string) => string.replace("#", ""));
                const words = phraseSplit
                    .filter((string) => !string.startsWith("#"))
                    .join(" ")
                    .trimStart()
                    .trimEnd();
                dispatch(setFilter({phrase: words, tags: tags}));
                dispatch(setPhrase(words));
              }}
          />
          <div className={classes.introLinkWrap}>
            <Link to="/verejne-zakazky" title="Katalog veřejných zakázek">
              <Button color={"primary"} className={globalClasses.btnTertiary}>
                Pokročilé filtrování
                <FontAwesomeIcon className={globalClasses.btnIcon} icon={faArrowRight}/>
              </Button>
            </Link>
          </div>
        </Container>
      </div>

      <Statistics/>

      <div className={classes.bottomWrap}>

        <CategoryList/>

        <div className={classes.hpNews}>
          <Container maxWidth="lg">
            <h2 className={classes.hpTitle}>Aktuality</h2>
            <NewsList limit={3} hp />
            <div className={classes.linkWrap}>
              <Link to="/aktuality" title="Další aktuality">
                <Button className={globalClasses.btnSecondary}>
                  Další aktuality
                  <FontAwesomeIcon className={globalClasses.btnIcon} icon={faArrowRight}/>
                </Button>
              </Link>
            </div>
            <hr className={classes.hr}/>
          </Container>
        </div>

        <PodoArguments/>

      </div>
    </div>
  );
};

export default Home;
