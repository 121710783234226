import React from "react";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { Container } from "@material-ui/core";
import { COLORS } from "../../../theme/theme";
import useStyles from "./Footer.styles";
import { Link } from "react-router-dom";

import GooglePlay from "../../../assets/google-play.png";
import AppStore from "../../../assets/appstore.png";
import FENLogo from "../../../assets/logo-fen-negative.png";

// Company logos imports
// import TenderMarket from "../../../assets/companies/TenderMarket-logo.png";
// import Ezak from "../../../assets/companies/E-ZAK-logo.png";
// import TenderArena from "../../../assets/companies/TenderArena-logo.png";
// import Pvu from "../../../assets/companies/PVU-logo.png";
// import Gemin from "../../../assets/companies/Gemin-logo.png";

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <Grid container className={classes.gridWrapper}>
          {/* Company logos */}
          {/* <Grid item xs={12}>
            <div className={classes.companiesWrapper}>
              <a href={"https://tenderarena.cz"} target={"_blank"} rel={"noreferrer"} title="TenderArena">
                <img
                  src={TenderArena}
                  alt={"TenderArena"}
                  width="100%"
                  height="100%"
                  className={classes.companyImage}
                />
              </a>
              <a href={"https://www.ezak.cz"} target={"_blank"} rel={"noreferrer"} title="E-ZAK">
                <img src={Ezak} alt={"E-ZAK"} width="100%" height="100%" className={classes.companyImage} />
              </a>
              <a href={"https://www.vhodne-uverejneni.cz"} target={"_blank"} rel={"noreferrer"} title="PVU">
                <img src={Pvu} alt={"PVU"} width="100%" height="100%" className={classes.companyImage} />
              </a>
              <a href={"https://www.tendermarket.cz"} target={"_blank"} rel={"noreferrer"} title="TenderMarket">
                <img
                  src={TenderMarket}
                  alt={"TenderMarket"}
                  width="100%"
                  height="100%"
                  className={classes.companyImage}
                />
              </a>
              <a href={"https://www.gemin.cz"} target={"_blank"} rel={"noreferrer"} title="GEMIN">
                <img src={Gemin} alt={"GEMIN"} width="100%" height="100%" className={classes.companyImage} />
              </a>
            </div>
          </Grid>
          <div className={classes.separator} /> */}
          <Grid item md={3} className={classes.footerCol + " " + classes.footerText}>
            <div className={classes.title}>Kontakt</div>
            <div className={classes.iconContainer}>
              <span className={classes.icon}>
                <FontAwesomeIcon icon={faEnvelope} size={"lg"} style={{color: COLORS.RED}}/>
              </span>

              <a className={classes.link} href="mailto:podo@fen.cz">
                podo@fen.cz
              </a>
            </div>
            <div className={classes.iconContainer}>
              <span className={classes.icon}>
                <FontAwesomeIcon icon={faPhoneAlt} size={"lg"} style={{color: COLORS.RED}}/>
              </span>

              <span>
                <a className={classes.link} href="tel:+420 538 702 710">
                  +420 538 702 710
                </a>
              </span>
            </div>
            <div className={classes.iconContainer}>
              <span className={classes.icon}>
                <FontAwesomeIcon icon={faClock} size={"lg"} style={{color: COLORS.RED}}/>
              </span>
              <span>pracovní dny: 09:00 - 16:00</span>
            </div>
            <div className={classes.iconsContainer}>
              <a href={process.env.REACT_APP_FEN_URL} target={"_blank"} rel={"noreferrer"} title="fen.cz"
                 style={{marginRight: 5}}>
                <img alt={"FEN logo"} src={FENLogo} className={classes.logoFen}/>
              </a>
              <a
                  href={"https://twitter.com/FEN2018a"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className={classes.socialIcon}
                  title={"Twitter"}
              >
                {/* @ts-ignore*/}
                <FontAwesomeIcon icon={faXTwitter} size={"lg"}/>
              </a>
            </div>
          </Grid>
          <Grid item md={3} className={classes.footerCol}>
            <div className={classes.title} style={{marginBottom: 13}}>Info o provozovateli</div>
            <div className={classes.footerText} style={{lineHeight: "27px"}}>
              <span>Spojené nástroje elektronické s.r.o.</span>
              <br/>
              <a className={classes.link} href="mailto:informace@fen.cz">
                informace@fen.cz
              </a>
              <br />
              <span>ID datové schránky: 2dk7anf</span>
            </div>
          </Grid>
          <Grid item md={3} className={classes.footerCol}>
            <div className={classes.title} style={{marginBottom: 18}}>Další informace</div>
            <div className={classes.footerText}>
              <Link
                  className={classes.link + " " + classes.mobileAppLink}
                  to="/aktuality" title="Aktuality">
                Aktuality
              </Link>
              <Link
                  className={classes.link + " " + classes.mobileAppLink}
                  to="/statistiky" title="Statistiky">
                Statistiky
              </Link>
              <Link
                  className={classes.link + " " + classes.mobileAppLink}
                  to="/faq" title="FAQ">
                FAQ
              </Link>
              <a
                  className={classes.link + " " + classes.mobileAppLink}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  title={"Mobilní aplikace"}
                  href="https://cdd.fen.cz/#/mobilni-aplikace"
              >
                Mobilní aplikace
              </a>
            </div>
          </Grid>
          <Grid item md={3} className={classes.footerCol}>

            <div className={classes.title}>Stáhněte si mobilní aplikaci</div>
            <div className={classes.footerText}>
              Souhrnné informace o aktuálních veřejných zakázkách v české republice ve Vašem mobilu.
              <div className={classes.mobileAppDownload}>
                <a href={"https://play.google.com/store/apps/details?id=cz.fen.mobileapp"}
                   className={classes.mobileAppGooglePlay} target={"_blank"} rel={"noreferrer"} title="GooglePlay"
                >
                  <img
                      src={GooglePlay}
                      alt={"GooglePlay"}
                      width="100%"
                      height="100%"
                      className={classes.companyImage}
                  />
                </a>
                <a href={"https://apps.apple.com/cz/app/ve%C5%99ejn%C3%A9-zak%C3%A1zky/id1478878066"}
                   target={"_blank"} rel={"noreferrer"} title="AppStore"
                >
                  <img
                      src={AppStore}
                      alt={"AppStore"}
                      width="100%"
                      height="100%"
                      className={classes.companyImage}
                  />
                </a>
              </div>
            </div>
            {/*<div className={classes.socialContainer}>*/}
              {/*<a*/}
              {/*  href={"https://www.facebook.com/qcmbrno/"}*/}
              {/*  target={"_blank"}*/}
              {/*  rel={"noopener noreferrer"}*/}
              {/*  className={classes.socialIcon}*/}
              {/*  title={"Facebook"}*/}
              {/*>*/}
                {/*  <FontAwesomeIcon icon={faFacebook} size={"lg"} />*/}
                {/*</a>*/}

                {/*<a*/}
                {/*    href={"https://twitter.com/FEN2018a"}*/}
                {/*    target={"_blank"}*/}
                {/*    rel={"noopener noreferrer"}*/}
                {/*    className={classes.socialIcon}*/}
                {/*    title={"Twitter"}*/}
                {/*>*/}
                {/*  /!* @ts-ignore*!/*/}
                {/*  <FontAwesomeIcon icon={faXTwitter} size={"lg"}/>*/}
                {/*</a>*/}

                {/*<a*/}
                {/*  href={"https://www.youtube.com/channel/UCK3kcg-TyhpgL0cqSK5GKSw"}*/}
                {/*  target={"_blank"}*/}
                {/*  rel={"noopener noreferrer"}*/}
                {/*  className={classes.socialIcon}*/}
                {/*  title={"YouTube"}*/}
                {/*>*/}
                {/*  <FontAwesomeIcon icon={faYoutube} size={"lg"} />*/}
                {/*</a>*/}
              {/*</div>*/}


          </Grid>
        </Grid>
      </Container>
      <Container className={classes.container}>
        <div  className={classes.bottom}>
          <p>Copyright 2024 - Portál Dodavatele</p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
