import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISubscriptionLimit, IUser } from "../../typings/user";

interface iFollowedContracts {
  id: string,
  contractId: string
}

interface UserState {
  init: boolean;
  user: IUser | null;
  cddInformation: any | null;
  status: {
    loading: boolean;
    error: boolean;
    success: boolean;
    message: string | null;
  };
  followedContracts: Array<iFollowedContracts>;
  subscriptionLimit: ISubscriptionLimit;
}

const DEF_LIMIT = { filters: 0, following_contracts: 0 };

const userSlice = createSlice({
  name: "user",
  initialState: {
    init: false,
    user: null,
    cddInformation: null,
    status: {
      loading: false,
      error: false,
      success: false,
      message: null
    },
    followedContracts: [],
    subscriptionLimit: DEF_LIMIT
  } as UserState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
    logInStart: (state) => {
      state.status.loading = true;
    },
    setUserInit: (state) => {
      state.init = true;
    },
    setSubscriptionLimit: (state, action: PayloadAction<ISubscriptionLimit>) => {
      state.subscriptionLimit = action.payload;
    },
    logInSuccess: (state, action: PayloadAction<{ user: IUser; cddInformation: any }>) => {
      const { status } = state;

      status.loading = false;
      status.success = true;
      state.init = true;
      state.user = action.payload.user;
      state.cddInformation = action.payload.cddInformation;
    },
    logInFailure: (state, action: PayloadAction<string>) => {
      const { status } = state;
      state.init = true;
      status.loading = false;
      status.error = true;
      status.message = action.payload;
    },
    setFollowedContractsStart: (state) => {
      state.status.loading = true;
    },
    setFollowedContracts: (state, action: PayloadAction<iFollowedContracts[]>) => {
      state.followedContracts = action.payload;
    },
    addFollowedContract: (state, action: PayloadAction<iFollowedContracts>) => {
      state.followedContracts.push(action.payload);
      state.status.loading = false;
      state.status.success = true;
    },
    deleteFollowedContract: (state, action: PayloadAction<string>) => {
      state.followedContracts = state.followedContracts.filter(f => f.contractId !== action.payload);
      state.status.loading = false;
      state.status.success = true;
    },
    setFollowedContractsFailure: (state, action: PayloadAction<string>) => {
      const { status } = state;
      status.loading = false;
      status.error = true;
      status.message = action.payload;
    },
    updateUserSettingsStart: (state) => {
      const { status } = state;
      status.loading = true;
    },
    updateUserSettingsSuccess: (state) => {
      const { status } = state;
      status.loading = false;
      status.success = true;
    },
    updateUserSettingsFailure: (state) => {
      const { status } = state;
      status.loading = false;
      status.error = true;
      status.message = "Při ukládání nastavení nastal problém.";
    },

    clearUserStatus: (state) => {
      const { status } = state;
      status.loading = false;
      status.error = false;
      status.success = false;
      status.message = "";
    },
    logOut: (state) => {
      state.user = null;
      state.followedContracts = [];
      state.subscriptionLimit = DEF_LIMIT;
    },
    locationChangeUser: (state) => {
      state.status.error = false;
      state.status.success = false;
      state.status.message = null;
    }
  }
});

const { actions, reducer } = userSlice;
export const {
  setUser,
  logInStart,
  logInSuccess,
  logInFailure,
  setUserInit,
  setSubscriptionLimit,
  setFollowedContractsStart,
  addFollowedContract,
  deleteFollowedContract,
  setFollowedContracts,
  setFollowedContractsFailure,
  updateUserSettingsStart,
  updateUserSettingsSuccess,
  updateUserSettingsFailure,
  clearUserStatus,
  logOut,
  locationChangeUser
} = actions;
export default reducer;
