import axios from "axios";
import { Tag } from "../typings/contract";


/**
 * API instance pro normalizér zakázek
 * Používá se například pro získání seznamu tagů
 */
class NormalizerApi {

  api = axios.create({
    baseURL: process.env.REACT_APP_NORMALIZER_API_URL
  });

  getTagsList = () =>
    this.api.get<Tag[]>(`tags/list`)
      .then(res => res.data);

}

export default new NormalizerApi();
