import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/user/selectors";


export const useUserHasToken = () => {
  const { keycloak } = useKeycloak();
  const user = useSelector(selectUser);
  return !!user && !!keycloak.token;
};

export const useFreeUserOrHasToken = () => {
  const userHasToken = useUserHasToken();
  const user = useSelector(selectUser);
  return user === null || userHasToken;
};