import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";
import { tokensGetIdToken, tokensGetRefreshToken, tokensGetToken } from "./tokens";

const config: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || ""

};
export const initOptions: KeycloakInitOptions = {
  onLoad: tokensGetToken() ? "check-sso" : undefined,
  pkceMethod: "S256",
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  checkLoginIframe: false,
  enableLogging: process.env.NODE_ENV === "development",
  token: tokensGetToken(),
  idToken: tokensGetIdToken(),
  refreshToken: tokensGetRefreshToken()
};

const keycloak = new Keycloak(config);

export default keycloak;
