import { makeStyles } from "@material-ui/core/styles";
import {COLORS} from "../../../../theme/theme";

const CategoryListStyles = makeStyles(() => ({
  wrapper:{
    paddingTop: 40,
    background: COLORS.BGBLUE,
  },
  card: {
    display: "flex",
    height: 200,
    background: "#0F2332",
    color: "#fff",
    overflow: "inherit",
    fontSize: 21,
    lineHeight: "26px",
    fontWeight: 700,
    textAlign: "left",
    boxShadow: "none",
    borderRadius: 0,
    transition: "0.25s",
    "&:hover": {
      background: COLORS.BGBLUEDARK
    },
    "&:hover $cardContent": {
      paddingBottom: 24
    },
    "&:hover $linkText": {
      display: "block",
      marginTop: 20,
      opacity: 1
    }
  },
  cardContent: {
    alignSelf: "flex-end",
    paddingBottom: 0,
    "&:last-child": {
      paddingBottom: 0,
      transition: "0.25s"
    }
  },
  title: {
    fontSize: 21,
    fontWeight: 700,
    lineHeight: "26px",
    color: "#fff"
  },
  linkText: {
    display: "block",
    opacity: 0,
    transition: "0.25s"
  }
}));

export default CategoryListStyles;