const CAPTCHA_LEN = 6;
const ENABLE_CHARS = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"; //@!#$%^&*

export const generateCaptcha = () => {
  const phrase = generateCaptchaPhrase();
  return {
    phrase: hashString(phrase),
    img: createCaptchaImg(phrase)
  };
};

export const hashString = (s: string) => s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

const generateCaptchaPhrase = () => {
  const captcha = [];
  for (let i = 0; i < CAPTCHA_LEN; i++) {
    //below code will not allow Repetition of Characters
    const index = Math.floor(Math.random() * ENABLE_CHARS.length + 1); //get the next character from the array
    if (captcha.indexOf(ENABLE_CHARS[index]) === -1) captcha.push(ENABLE_CHARS[index]);
    else i--;
  }
  return captcha.join("");
};

const createCaptchaImg = (captchaPhrase: string) => {
  const canv = document.createElement("canvas");
  canv.id = "captcha";
  canv.width = 20 * CAPTCHA_LEN;
  canv.height = 50;
  const ctx = canv.getContext("2d")!;

  const drawRandomLine = () => {
    // Náhodné souřadnice čáry
    const startX = Math.random() * canv.width;
    const startY = Math.random() * canv.height;
    const endX = Math.random() * canv.width;
    const endY = Math.random() * canv.height;

    // Náhodná barva čáry
    const lineColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    const lineWidth = Math.random() + 1;

    // Vykreslení náhodné čáry
    ctx.strokeStyle = lineColor;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();
  };
  // const drawRandomObj = () => {
  //   // Náhodné souřadnice a rozměry tvaru
  //   const x = Math.random() * canv.width
  //   const y = Math.random() * canv.height
  //   const width = Math.random() * canv.width - x
  //   const height = Math.random() * canv.height - y
  //
  //   // Náhodná barva tvaru
  //   const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
  //
  //   // Vykreslení náhodného tvaru
  //   ctx.fillStyle = color
  //   ctx.fillRect(x, y, width, height)
  // }

  ctx.strokeStyle = "#000";
  ctx.font = "25px Georgia";
  ctx.strokeText(captchaPhrase, 0, 30);

  // Vykreslení náhodných tvarů a čar
  for (let i = 0; i < 5; i++) {
    // drawRandomObj()
    drawRandomLine();
  }

  return canv.toDataURL();

};
