import axios from "axios";
import qs from "query-string";
import { interceptorRequestAuthToken } from "./interceptorRequestAuthToken";
import { interceptorResponseAuthToken } from "./interceptorResponseAuthToken";
import { SortParam } from "../typings/common";
import { IFilterBackend, IFilterParams, IFilterResponse } from "../typings/filters";
import { ContractStatistics, IContract, iExtContract } from "../typings/contract";
import { interceptorRequestSecureToken } from "./interceptorRequestSecureToken";
import { interceptorResponseSecureToken } from "./interceptorResponseSecureToken";
import { interceptorResponseSecureCaptcha } from "./interceptorResponseSecureCaptcha";
import { ISubscriptionLimit, IUser } from "../typings/user";


const PATCH_HEADERS = { "Content-Type": "application/merge-patch+json" };

/**
 * API instance pro backend
 */
class PodoApi {

  api = axios.create({
    baseURL: process.env.REACT_APP_API_URL_V2,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "bracket" })
  });

  constructor() {
    interceptorResponseSecureCaptcha(this.api);
    interceptorRequestSecureToken(this.api);
    interceptorResponseSecureToken(this.api);
    interceptorRequestAuthToken(this.api);
    interceptorResponseAuthToken(this.api);
  }

  alive = () =>
    this.api.get("alive", { headers: { "Authorization": "none" } })
      .then(res => res.data);

  authenticateUser = () =>
    this.api.get("users/me")
      .then(res => res.data);

  editUserSettings = (userId: string, data: Partial<IUser>) =>
    this.api.patch(`users/${userId}`, data, { headers: PATCH_HEADERS })
      .then(res => res.data);

  sendUserFeedback = (msg: string, copyToAuthor: boolean) =>
    this.api.post(`users/feedback`, {
      message: msg,
      copyToAuthor: copyToAuthor
    })
      .then(res => res.data);

  /*
  Following
   */
  getFollowingContracts = () =>
    this.api.get("following_contracts", { params: { itemsPerPage: 40 } })
      .then(res => res.data);
// <Response<ContractResponseData>>

  addFollowingContract = (contractId: string) =>
    this.api.post("following_contracts", { "contractId": contractId })
      .then(res => res.data);

  deleteFollowingContract = (contractId: string) =>
    this.api.delete(`following_contracts/${contractId}`)
      .then(res => res.data);

  /*
  Submitters
   */
  getSubmitters = (rowsPerPage = 20, page = 1, sort: SortParam) =>
    this.api.get(`submitters`, {
      params: {
        page: page,
        itemsPerPage: rowsPerPage,
        ...((sort && sort.field) && { [`order[${sort.field}]`]: sort.order })
      }
    })
      .then(res => ({
        data: res.data,
        results: parseInt(res.headers["x-total-count"])
      }));

  getSubmittersSearch = (rowsPerPage = 20, page = 1, sort: SortParam, name?: string, ico?: string) =>
    this.api.get(`submitters/search`, {
      params: {
        page: page,
        itemsPerPage: rowsPerPage,
        ...(name && { name }),
        ...(ico && { ico }),
        ...((sort && sort.field) && { [`order[${sort.field}]`]: sort.order })
      }
    })
      .then(res => ({
        data: res.data,
        results: parseInt(res.headers["x-total-count"])
      }));

  getSubmittersSuggest = (phrase: string, rowsPerPage = 20, page = 1) =>
    this.api.get(`submitters/suggest`, {
      params: {
        page: page,
        itemsPerPage: rowsPerPage,
        phrase: phrase
      }
    })
      .then(res => ({
        data: res.data,
        results: parseInt(res.headers["x-total-count"])
      }));

  getSubmitterInformation = (slug: string) =>
    this.api.get(`submitters/${slug}`)
      .then((res) => res.data);

  getSubmitterContracts = (slug: string, page = 1, size = 20, sort: SortParam) =>
    this.api.get(`/contracts/submitter/${slug}`, {
      params: {
        page: page,
        itemsPerPage: size,
        ...((sort && sort.field) && { [`order[${sort.field}]`]: sort.order })
      }
    })
      .then(res => ({
        data: res.data,
        results: parseInt(res.headers["x-total-count"])
      }));

  /*
   Filters
   */

  fetchUserFilters = () =>
    this.api.get<IFilterResponse[]>("filters", { params: { "order[createdAt]": "desc", itemsPerPage: 25 } })
      .then((res) => res.data);

  fetchUserFilterDetail = (filterId: string) =>
    this.api.get<IFilterResponse>(`filters/${filterId}`)
      .then((res) => res.data);


  createUserFilter = (filter: IFilterBackend) =>
    this.api.post("filters", filter)
      .then((res) => res.data);


  updateUserFilter = (filterId: string, filter: IFilterBackend) =>
    this.api.put(`filters/${filterId}`, filter)
      .then((res) => res.data);


  fetchContractsForFilter = (filter: IFilterParams, page = 1, size = 20) =>
    this.api.post("contracts/search", filter, {
      params: {
        type: "active",
        page: page,
        itemsPerPage: size,
        "order[createAt]": "desc"
      }
    }).then(res => ({
      data: res.data,
      results: parseInt(res.headers["x-total-count"])
    }));


  deleteUserFilter = (filterId: string) =>
    this.api.delete(`filters/${filterId}`)
      .then((res) => res.data);

  validateUserFilter = (filterId: string, extended?: boolean) =>
    this.api.get(`filters/${filterId}/validate`, { params: { extended: extended } })
      .then((res) => res.data);

  validateFilterParams = (filter: IFilterParams, extended?: boolean) =>
    this.api.post(`filters/validate_params`, filter, { params: { extended: extended } })
      .then((res) => res.data);

  /*
  Contracts
   */
  getContractDetail = (slug: string, accessToken: string | null = null) =>
    this.api.get<IContract>(`contracts/${slug}`, {
      params: accessToken ? { accessToken } : {}
    })
      .then((res) => res.data);

  getContracts = (page = 1, size = 20, sort?: SortParam) =>
    this.api.get<IContract[]>("contracts", {
      params: {
        page: page,
        itemsPerPage: size,
        ...((sort && sort.field) && { [`order[${sort.field}]`]: sort.order })
      }
    })
      .then(res => ({
        data: res.data,
        results: parseInt(res.headers["x-total-count"])
      }));

  fetchContractsSearch = (filter: IFilterParams, page = 1, size = 20, sort: SortParam, isFull: boolean) =>
    this.api.post<IContract[]>("contracts/search", filter, {
      params: {
        type: isFull ? "all" : "active",
        page: page,
        itemsPerPage: size,
        ...((sort && sort.field) && { [`order[${sort.field}]`]: sort.order })
      }
    })
      .then(res => ({
        data: res.data,
        results: parseInt(res.headers["x-total-count"])
      }));

  fetchContractSuggest = (phrase: string, tags: string[]) =>
    this.api.get<IContract[]>(`contracts/suggest`, {
      params: {
        page: 1,
        itemsPerPage: 20,
        ...(tags && { tags }),
        ...(phrase && { phrase })
      }
    })
      .then((res) => res.data);

  fetchContractSlugByExt = (extContracts: iExtContract[]) =>
    this.api.post("contracts/search_by_external_data", extContracts)
      .then((res) => res.data);

  /*
  Devices
   */
  getDevices = () =>
    this.api.get("devices")
      .then((res) => res.data);

  deleteDevice = (deviceId: string) =>
    this.api.delete(`devices/${deviceId}`)
      .then((res) => res.data);

  getContractStatistics = () => {
    return this.api.get<ContractStatistics>("contracts/stats", { params: { noCache: true } })
      .then((response) => response.data);
  };

  getGAUserData = () =>
    this.api.get("ga")
      .then((res) => res.data);

  getSubscriptionLimit = () =>
    this.api.get<ISubscriptionLimit>("subscriptions/limits/me")
      .then((res) => res.data);

}

export default new PodoApi();
