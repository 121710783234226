import React from "react";
import { RouteProps } from "../components/Route/Route";
import Home from "../pages/Home/Home.container";
import { EKOM_LINKS } from "@qcm/ekom-lib";

const ContractCatalog = React.lazy(() => import("../pages/Contract/Catalog/Catalog.container"));
const ContractDetail = React.lazy(() => import("../pages/Contract/Detail/Detail.container"));
const FollowedContainer = React.lazy(() => import("../pages/Contract/Followed/Followed.container"));
const SubmitterCatalog = React.lazy(() => import("../pages/Submitter/Catalog/Catalog.container"));
const SubmitterDetail = React.lazy(() => import("../pages/Submitter/Detail/Detail.container"));
const ListContainer = React.lazy(() => import("../pages/Filter/List/List.container"));
const Edit = React.lazy(() => import("../pages/Filter/Edit/Edit.container"));
const Contracts = React.lazy(() => import("../pages/Filter/Contracts/Contracts.container"));
const Eshop = React.lazy(() => import("../pages/Eshop/Eshop"));
const Stats = React.lazy(() => import("../pages/Stats/Stats"));
const TermsOfSale = React.lazy(() => import("../pages/TermsOfSale/TermsOfSale"));
const Billing = React.lazy(() => import("../pages/Profile/Billing/Billing.container"));
const PaymentContainer = React.lazy(() => import("../pages/Payment/Payment.container"));
const Registration = React.lazy(() => import("../pages/Registration/Registration"));
const EkomRouter = React.lazy(() => import("../pages/Ekom/EkomRouter"));
const Faq = React.lazy(() => import("../pages/Faq/Faq"))
const NewsListPage = React.lazy(() => import("../pages/News/NewsListPage"))
const NewsDetailPage = React.lazy(() => import("../pages/News/NewsDetailPage"))
const ProfilPage = React.lazy(() => import("../pages/Profile/Overview/Overview.container"))


const routes: Array<RouteProps> = [
  {
    path: "/",
    component: Home,
    exact: true
  },
  {
    path: "/verejne-zakazky",
    component: ContractCatalog,
    exact: true
  },
  {
    path: "/verejne-zakazky/:slug",
    component: ContractDetail,
    exact: true
  },
  {
    path: "/sledovane-zakazky",
    component: FollowedContainer,
    exact: true
  },
  {
    path: "/zadavatele-verejnych-zakazek",
    component: SubmitterCatalog,
    exact: true
  },
  {
    path: "/zadavatele-verejnych-zakazek/:submitterSlug",
    component: SubmitterDetail,
    exact: true
  },
  {
    path: "/filtry-a-notifikace",
    component: ListContainer,
    exact: true
  },
  {
    path: "/filtry-a-notifikace/novy-filtr",
    component: Edit,
    exact: true
  },
  {
    path: "/filtry-a-notifikace/:id?",
    component: Edit,
    exact: true
  },
  {
    path: "/filtry-a-notifikace/:id/verejne-zakazky",
    component: Contracts,
    exact: true
  },
  {
    path: "/cenik",
    component: Eshop,
    exact: true
  },
  {
    path: "/statistiky",
    component: Stats,
    exact: true
  },
  {
    path: "/payment",
    component: PaymentContainer
  },
  {
    path: "/profil",
    component: ProfilPage,
    loginRequired: true,
    exact: true
  },
  {
    path: "/profil/nastaveni",
    component: Billing,
    loginRequired: true,
    exact: true
  },
  {
    path: "/registrace",
    component: Registration,
    exact: true
  },
  {
    path: "/vseobecne-obchodni-podminky",
    component: TermsOfSale,
    exact: true
  },
  {
    path: "/aktuality",
    component: NewsListPage,
    exact: true
  },
  {
    path: "/aktuality/:slug",
    component: NewsDetailPage,
    exact: true
  },
  {
    path: "/faq",
    component: Faq,
    exact: true
  },
  {
    path: EKOM_LINKS.base,
    component: EkomRouter,
    exact: false
  }
];

export default routes;
