import React, { useEffect, useState } from "react";
import { generateCaptcha, hashString } from "./captchaGenerator";
import { Button } from "@material-ui/core";
import globalStyles from "../../theme/global.styles";

interface iCaptcha {
  infoMsg?: React.ReactNode;
  onVerify?: () => void;
  children: React.ReactNode;
}

const TRY_COUNT = 5;
const WAIT_TIME = 30;

const Captcha = ({ infoMsg, onVerify, children }: iCaptcha) => {
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [timer, setTimer] = useState(0);
  const [tryCount, setTryCount] = useState(TRY_COUNT);
  const [inputVal, setInputVal] = useState("");
  const [valid, setValid] = useState<boolean | undefined>(undefined);
  const globalClasses = globalStyles();

  const TEXTS = {
    title: "Překročen limit dotazů pro nepřihlášené uživatele. Opište prosím text z obrázku pro potvrzení, že nejste robot.",
    err: "Chyba. Text není stejný.",
    other: "Jiný obrázek",
    verify: "Ověřit"
  };

  useEffect(() => {
    if (tryCount < 1) {
      setTimer(WAIT_TIME);
      setTryCount(TRY_COUNT);
      setValid(undefined);
    }
  }, [tryCount]);


  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (timer > 0) {
      timeoutId = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timer]);

  const handleReload = () => {
    setCaptcha(generateCaptcha());
  };

  const validateCaptcha = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (hashString(inputVal) === captcha.phrase) {
      setValid(true);
      onVerify && onVerify();
    } else {
      setValid(false);
      setInputVal("");
      handleReload();
      setTryCount(tryCount - 1);
    }
  };

  if (valid) {
    return <>{children}</>;
  }

  if (timer > 0) {
    return (
      <div className="text-danger font-weight-bold my-3">
        Více než {TRY_COUNT} neplatných pokusů. Čekejte: {timer}s
      </div>
    );
  }

  return (
    <div style={{ textAlign: "center", marginBottom: 30 }} className="captchaBlock">
      <form>
        {infoMsg}
        <h4>{TEXTS.title}</h4>
        {valid === false && (
          <div style={{ fontWeight: "bold", color: "red" }}>{TEXTS.err}</div>
        )}
        <div>
          <img alt="Captcha img" src={captcha.img} />
          <small style={{ cursor: "pointer", textDecoration: "underline" }} onClick={handleReload}>
            {TEXTS.other}
          </small>
        </div>
        <div>
          <input
            style={{ width: 90, padding: 11, fontSize: 16, marginRight: 15 }}
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
          />
          <Button
              type="button"
              onClick={validateCaptcha}
              className={globalClasses.btnPrimary}
              style={{paddingTop: 10, paddingBottom: 10}}
              color="primary"
          >
          {/*<button className="" onClick={validateCaptcha}>*/}
            {TEXTS.verify}
          {/*</button>*/}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Captcha;
