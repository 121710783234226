import React, { useState } from "react";
import useStyles from "../../Home.styles";
import CategoryPickerModalWrap from "../../../../components/CategoryPickerModal/CategoryPickerModalWrap";
import globalStyles from "../../../../theme/global.styles";

const CategoryPicker: React.FC = () => {
  const globalClasses = globalStyles();
  const classes = useStyles();
  const [pickerModalOpen, setPickerModalOpen] = useState(false);
  const handleClick = () => setPickerModalOpen(true);

  return (
    <div className={classes.chooseCategoryButton + " " + classes.linkWrap}>
      <span className={globalClasses.btnSecondary + " " + classes.modalLink} onClick={handleClick}>
        Všechny kategorie
      </span>
      <CategoryPickerModalWrap open={pickerModalOpen} setOpen={setPickerModalOpen}/>
    </div>
  )
};

export default CategoryPicker;
