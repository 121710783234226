import { gaEventClickCddLogin } from "../utils/ga";
import { useKeycloakActions } from "./useKeycloakActions";

export const useHandleLogin = () => {

  const { keycloakLogin } = useKeycloakActions();

  const handleLogin = () => {
    gaEventClickCddLogin();
    const baseUrl = window.location.href.split("#")[0]; // URL bez hashe
    const hash = window.location.hash;
    keycloakLogin(`${baseUrl}${window.location.search === "" ? "?" : "&"}loginByUser=true${hash}`);
  };

  return { handleLogin };
};
