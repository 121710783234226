import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import Route, { RouteProps } from "../components/Route/Route";
import NotFoundErrorPage from "../pages/Errors/NotFoundErrorPage";
import ForbiddenErrorPage from "../pages/Errors/ForbiddenErrorPage";
import InternalServerErrorPage from "../pages/Errors/InternalServerErrorPage";
import ServiceUnavailableErrorPage from "../pages/Errors/ServiceUnavailableErrorPage";
import { redirectNotFound } from "./redirectError";
import UnauthorizedErrorPage from "../pages/Errors/UnauthorizedErrorPage";

const renderRoutes = (routes: Array<RouteProps>, extraProps = {}, switchProps = {}) =>
  routes && (
    <Suspense fallback={null}>
      <Switch {...switchProps}>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
            loginRequired={!!route.loginRequired}
          />
        ))}

        <Route path="/401-unauthorized" component={UnauthorizedErrorPage} />
        <Route path="/403-forbidden" component={ForbiddenErrorPage} />
        <Route path="/404-not-found" component={NotFoundErrorPage} />
        <Route path="/500-internal-server-error" component={InternalServerErrorPage} />
        <Route path="/503-service-unavailable" component={ServiceUnavailableErrorPage} />
        <Route component={redirectNotFound} />
      </Switch>
    </Suspense>
  );

export default renderRoutes;
