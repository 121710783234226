import { makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../../theme/theme";

export const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: COLORS.LIGHTGREY,
    "&::after": {
      display: "block",
      content: '" "',
      width: "100%",
      height: 1,
      background: "#B4B4B4"
    },
    "& a": {
      textDecoration: "none"
    }
  },
  logos: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  logoLink: {
    color: COLORS.BLUE,
    textTransform: "uppercase",
    fontWeight: 800
  },
  logo: {
    width: 155,
    [theme.breakpoints.down("xs")]: {
      width: 120
    }
  },
  maintenanceInfo: {
    position: "absolute",
    top: 65,
    left: 0,
    right: 0,
    paddingTop: 5,
    paddingBottom: 5,
    zIndex: 1,
    background: COLORS.RED,
    // background: "repeating-linear-gradient(135deg, #c73b3b, #c73b3b 10px, #8b2929 10px, #8b2929 20px)",
    textAlign: "center",
    fontWeight: 400,
    color: "#fff",
    fontSize: "1rem",
    "& a": {
      color: "inherit !important",
      textDecoration: "underline"
    },
    [theme.breakpoints.down("xs")]: {
      top: 57
    }
  },
  useMobileAppInfo: {
    zIndex: 2,
    top: 57,
    padding: "5px 25px",
    "& a": {
      fontWeight: 700,
      textDecoration: "underline",
    },
    "& span": {
      position: "absolute",
      right: 2,
      top: 2,
      fontSize: "2rem",
      lineHeight: "2rem",
      padding: "0 5px"
    }
  }
}));

export default useStyles;
