import axios, { AxiosInstance } from "axios";
import { Security } from "../utils/Security";
import SecureTokensFetch from "../utils/SecureTokens";


export const interceptorResponseSecureToken = (axiosApiInstance: AxiosInstance) => {

  axiosApiInstance.interceptors.response.use(
    response => response,
    error => {

      const originalRequest = error.config;

      if (error.response?.status === 401 && [2901, 2900].includes(error.response?.data?.code)) {
        return SecureTokensFetch.fetchAlive().then(data => {
          originalRequest.headers["x-whistler"] = Security.o(data);
          return axios(originalRequest);
        }).catch(err => {
          console.log("catch interceptorResponseSecureToken", err);
        });
      }

      return Promise.reject(error);
    }
  );
};
