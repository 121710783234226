import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../theme/theme";

export const useStyles = makeStyles((theme) => ({
  item: {
    color: COLORS.BGBLUEDARK,
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "none",
    transition: "color 0.25s",
    "&:hover": {
      color: COLORS.RED,
      backgroundColor: "transparent"
    }
  },
  itemProfile: {
    color: COLORS.BGBLUEDARK,
    fontWeight: "bold",
    marginLeft: 5,
    borderRadius: 0,
    textTransform: "none",
    transition: "color 0.25s",
    "&:hover": {
      color: COLORS.RED,
      backgroundColor: "transparent"
    }
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  userMenuHeader: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifySelf: "center",
    alignItems: "center"
  },
  menuLink: {
    textDecoration: "none"
  },
  menuItem: {
    fontSize: 14
  },
  badge: {
    position: "relative",
    top: -10
  }
}));

export default useStyles;
