import React, { ReactNode } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import useStyles from "./PageWrap.styles";
import BreadcrumbNavigation from "../Layout/BreadcrumbNavigation/BreadcrumbNavigation";
import globalStyles from "../../theme/global.styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faReply} from "@fortawesome/free-solid-svg-icons";

const MAINTENANCE_TEXT = process.env.REACT_APP_MAINTENANCE_TEXT;
const MAINTENANCE_SHOW = process.env.REACT_APP_MAINTENANCE_SHOW;
const infoBar = MAINTENANCE_TEXT && MAINTENANCE_SHOW && (MAINTENANCE_SHOW === '1' || MAINTENANCE_SHOW === 'true')

interface IProps {
  title: string;
  subtitle?: string | ReactNode;
  tooltip?: ReactNode;
  children?: ReactNode;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  breadcrumbNavigation?: Array<{ label: string; path?: string }>;
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  titleWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
}

const PageWrap: React.FC<IProps> = ({
  title,
  subtitle,
  children,
  showBackButton,
  onBackButtonClick,
  breadcrumbNavigation,
  tooltip,
  wrapperProps,
  titleWrapperProps
}: IProps) => {
  const globalClasses = globalStyles();
  const classes = useStyles();

  return (
    <div {...wrapperProps} className={`${classes.wrap} ${wrapperProps?.className}`}>
      <Container maxWidth="lg">
        <div id="breadcrumb" style={{marginTop: infoBar ? 40 : 0}}>
          <small>
            <BreadcrumbNavigation breadcrumbNavigation={breadcrumbNavigation} />
          </small>
        </div>

        {showBackButton && (
          <div className={classes.backButtonWrapper}>
            <Button
                onClick={onBackButtonClick}
                color="primary"
                className={globalClasses.btnOutlined}
                style={{paddingTop: 10, paddingBottom: 10}}
                startIcon={<FontAwesomeIcon icon={faReply} />}
            >
              Zpět
            </Button>
          </div>
        )}

        <div
          {...titleWrapperProps}
          id="page-title"
          className={`${classes.titleWrapper} ${titleWrapperProps?.className}`}
        >
          {title &&
          <div>
            <h1 className={classes.title}>
              {title} {tooltip}
            </h1>
          </div>
          }
          {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
        </div>
        {children}
      </Container>
    </div>
  );
};

export default PageWrap;
