import React from "react";
import ReactDOM from "react-dom";
import { cs } from "date-fns/locale";
import { COLORS, theme } from "./theme/theme";
import App from "./components/App/App";
import { Provider } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/store";
import { MuiThemeProvider, StylesProvider, ThemeProvider } from "@material-ui/core";
import keycloak, { initOptions } from "./utils/keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./index.css";
import { gaInit } from "./utils/ga";

import {
  EkomApi,
  FenApi,
  IenApi,
  registerFetchFce,
  registerLoader,
  registerLoaderMin,
  registerToast
} from "@qcm/ekom-lib";
import { interceptorRequestAuthToken } from "./api/interceptorRequestAuthToken";
import { interceptorResponseAuthToken, logOutUser } from "./api/interceptorResponseAuthToken";
import LoadingBox from "./components/LoadingBox/LoadingBox";
import PuffLoader from "react-spinners/PuffLoader";
import { toast } from "react-toastify";
import { tokensSave } from "./utils/tokens";
import PodoApi from "./api/PodoApi";


registerLoader(<LoadingBox />);
registerFetchFce(PodoApi.fetchContractSlugByExt as any);
registerLoaderMin(<div style={{ marginTop: -15 }}><PuffLoader color={COLORS.RED} size={30} /></div>);
registerToast(toast);

EkomApi.init(process.env.REACT_APP_API_EKOM_URL);
interceptorRequestAuthToken(EkomApi.api);
interceptorResponseAuthToken(EkomApi.api);

FenApi.init(process.env.REACT_APP_API_FEN_URL);
interceptorRequestAuthToken(FenApi.api);
interceptorResponseAuthToken(FenApi.api);

interceptorRequestAuthToken(IenApi.api);
interceptorResponseAuthToken(IenApi.api, false);


gaInit();

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      // autoRefreshToken
      onEvent={(event) => {
        if (event === "onAuthRefreshError") {
          logOutUser();
        }
      }}
      initOptions={initOptions}
      onTokens={(tokens) => {
        if (tokens.token) {
          tokensSave(tokens);
        }
      }}
    >
      <Provider store={store}>
        <StylesProvider>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
                <App />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
