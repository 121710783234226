import React from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import { WbIncandescentOutlined, Search, NotificationsActive, Check} from "@material-ui/icons";
import PodoArgumentsStyles from "./PodoArguments.styles";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faFilter, faSearch, faLightbulb} from "@fortawesome/free-solid-svg-icons";

const data = [
  {
    icon: <Search style={{fontSize: "3rem"}}/>,
    title: "Pokročilé vyhledávání vždy najde ty správné zakázky pro Vás",
    text: "Zakázky můžete hledat podle mnoha kritérií. Náš našeptávač s podporou umělé inteligence vám poradí, jak se dobrat ke správné zakázce."
  },
  {
    // icon: <FontAwesomeIcon style={{fontSize: "2.5rem"}} icon={faFilter}/>,
    icon: <WbIncandescentOutlined style={{fontSize: "3rem"}}/>,
    title: "Díky inteligentním filtrům Vám neunikne žádná zakázka",
    text: "Určete, jaké zakázky chcete sledovat a systém bude 24 x 7 podle nich pečlivě a důsledně vyhledávat všechny zakázky."
  },
  {
    icon: <NotificationsActive style={{fontSize: "3rem"}}/>,
    title: "Informace o zakázkách dříve než ostatní",
    text: "Buďte napřed před konkurencí, dostávejte zprávy o zakázkách ihned, jak se objeví, nebo podle určeného rozvrhu."
  },
  {
    icon: <Check style={{fontSize: "3rem"}}/>,
    title: "Sledujte život vybraných zakázek do posledního detailu",
    text: "Nechejte si posílat informace o každé změně u vybraných zakázek."
  }
]

const PodoArguments: React.FC = () => {
  const podoArgumentsClasses = PodoArgumentsStyles();

  return (
      <div className={podoArgumentsClasses.wrapper}>
        <h2 className={podoArgumentsClasses.midTitle}>Proč používat nástroj Portál Dodavatele?</h2>
        <Grid container className={podoArgumentsClasses.cardWrapper} spacing={2}>
          {data.map((item) => (
              <Grid item xs={12} sm={6} md={3} className={podoArgumentsClasses.gridItem}>
                <Card className={podoArgumentsClasses.card}>
                  <CardContent>
                    <div className={podoArgumentsClasses.cardIcon}>
                      {item.icon}
                    </div>
                    <Typography variant="h3" component="div" className={podoArgumentsClasses.textTitle}>
                      {item.title}
                    </Typography>
                    <Typography variant="h5" component="div" className={podoArgumentsClasses.text}>
                      {item.text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
          ))}
        </Grid>
     </div>
  );
};

export default PodoArguments;