import { createBrowserHistory } from "history";

export const scrollIntoElementViewByName = (elementName: string, position = 0) => {
  const element = document.getElementsByName(elementName)[position || 0];
  const headerOffset = 72;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
};

export const scrollIntoElementViewByClass: (className: string, position?: number) => void = (
  className: string,
  position = 0
) => {
  const element = document.getElementsByClassName(className)[position || 0];

  if (!element) return;

  const headerOffset = 72;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
};

export const setMetaTags = (title: string, description: string = "Všechny veřejné zakázky na jednom místě.", keywords?: string[]) => {
  document.title = title;
  document.querySelector("meta[name='description']")?.setAttribute("content", description);
  if (keywords) {
    document.querySelector("meta[name='keywords']")?.setAttribute("content", keywords.filter(word => word).join(", "));
  } else {
    document.querySelector("meta[name='keywords']")?.remove();
  }
};


const baseURL = process.env.PUBLIC_URL;

export const history = createBrowserHistory({ basename: baseURL });
