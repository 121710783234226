import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { COLORS } from "../../theme/theme";
import globalStyles from "../../theme/global.styles";


const LoadingBox = () => {
  const globalClasses = globalStyles();

  return (
    <div className={globalClasses.spinner}>
      <PuffLoader color={COLORS.RED} size={60} />
    </div>
  );
};

export default LoadingBox;