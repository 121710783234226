import { combineReducers } from "redux";
import catalogueReducer from "./contractCatalogue/contractCatalogue";
import userReducer from "./user/user";
import filtersReducer from "./filters/filters";
import globalReducer from "./global/global";
import eshopReducer from "./eshop/eshop";
import profileReducer from "./profile/profile";
import submitterReducer from "./submitterProfile/submitterProfile";
import submitterCatalogueReducer from "./submitterCatalogue/submitterCatalogue";

const rootReducer = combineReducers({
  global: globalReducer,
  contractCatalogue: catalogueReducer,
  user: userReducer,
  profile: profileReducer,
  submitterCatalogue: submitterCatalogueReducer,
  submitterProfile: submitterReducer,
  filters: filtersReducer,
  eshop: eshopReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
