import { AxiosInstance } from "axios";
import { Security } from "../utils/Security";
import SecureTokensFetch from "../utils/SecureTokens";
import { store } from "../redux/store";
import { selectUser } from "../redux/user/selectors";


export const interceptorRequestSecureToken = (axiosApiInstance: AxiosInstance) => {

  axiosApiInstance.interceptors.request.use(async config => {

      const user = selectUser(store.getState());
      if (!user && (config.url?.startsWith("contracts") || config.url?.startsWith("submitters"))) {

        let secureToken = SecureTokensFetch.getToken();
        if (secureToken) {
          config.headers.set("x-whistler", Security.o(secureToken));
        } else {
          await SecureTokensFetch.fetchAlive().then(data => {
            config.headers.set("x-whistler", Security.o(data));
          }).catch(err => {
            console.log("catch interceptorRequestSecureToken", err);
          });
        }
      }

      return config;
    }
  );
};
