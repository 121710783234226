import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IContract } from "../../typings/contract";
import { Submitter } from "../../typings/submitter";
import { SortField, SortOrder } from "../../enum/sort";

interface State {
  submitter: Submitter | null;
  contracts: Array<IContract> | null;
  results: number;
  page: number;
  sort: {
    field: string;
    order: string;
  };
  rowsPerPage: number;
  status: {
    omit: boolean;
    loading: boolean;
    contractsLoading: boolean;
    error: boolean;
    success: boolean;
  };
}

const INITIAL_STATE: State = {
  submitter: null,
  contracts: null,
  results: 0,
  page: 1,
  sort: {
    field: SortField.BEGINS,
    order: SortOrder.DESC
  },
  rowsPerPage: 20,
  status: {
    omit: false,
    loading: false,
    contractsLoading: false,
    error: false,
    success: false
  }
};

const submitterProfileSlice = createSlice({
  name: "submitterProfile",
  initialState: INITIAL_STATE,
  reducers: {
    getInformationStart: (state) => {
      state.status.loading = true;
    },
    getInformationSuccess: (
      state,
      { payload }: PayloadAction<{ submitter: Submitter; contracts: Array<IContract>; results: number }>
    ) => {
      state.status.loading = false;
      state.submitter = payload.submitter;
      state.contracts = payload.contracts;
      state.results =  Math.min(payload.results, 10000); // omezeni max polozek z elasticsearch
    },
    getInformationFailure: (state) => {
      state.status.loading = false;
      state.status.error = true;
    },
    getContractsForPageStart: (state, { payload }: PayloadAction<{ omit?: boolean }>) => {
      state.status.contractsLoading = true;
      state.status.omit = !!payload.omit;
    },
    getContractsForPageSuccess: (
      state,
      { payload }: PayloadAction<{ contracts: Array<IContract>; page: number; results: number; omit: boolean }>
    ) => {
      state.status.contractsLoading = false;
      state.status.omit = !!payload.omit;
      state.contracts = payload.contracts;
      state.results = Math.min(payload.results, 10000); // omezeni max polozek z elasticsearch
      state.page = payload.page;
    },
    getContractsForPageFailure: (state) => {
      state.status.contractsLoading = false;
      state.status.error = true;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSortField: (state, { payload }: PayloadAction<string>) => {
      state.sort.field = payload;
    },
    setSortOrder: (state, { payload }: PayloadAction<string>) => {
      state.sort.order = payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    clearSubmitterProfile: (state) => {
      state.contracts = null;
      state.results = 0;
      state.status = {
        omit: false,
        loading: false,
        contractsLoading: false,
        error: false,
        success: false
      };
    }
  }
});

const { actions, reducer } = submitterProfileSlice;
export const {
  getInformationStart,
  getInformationSuccess,
  getInformationFailure,
  getContractsForPageStart,
  getContractsForPageSuccess,
  getContractsForPageFailure,
  setSortField,
  setSortOrder,
  setPage,
  setRowsPerPage,
  clearSubmitterProfile
} = actions;
export default reducer;
