import { RootState } from "../root-reducer";
import { IUser } from "../../typings/user";

export const selectFollowedContracts = (state: RootState) =>
  state.user.followedContracts.map(f => f.contractId) || [];

export const selectUser = (state: RootState): IUser | null => state.user.user;

export const selectUserInit = (state: RootState) => state.user.init;


export const selectUserName = (state: RootState) => {
  const { user, cddInformation } = state.user;
  return cddInformation?.user?.firstName && cddInformation?.user?.lastName
    ? `${cddInformation?.user?.firstName} ${cddInformation?.user?.lastName}`
    : `${user?.firstName} ${user?.lastName}`;
};

export const selectUserCanCreateFilter = (state: RootState) => {
  const { user, subscriptionLimit } = state.user;
  const { filters } = state.filters;
  const max = subscriptionLimit.filters || 0;
  return user && filters.length < max;
};

