import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IContract } from "../../../typings/contract";
import ContractSearcherStyles from "../ContractSearcher.styles";
import { gaEventViewDetail } from "../../../utils/ga";

interface ContractListProps {
  contracts: Array<IContract>;
  isLoading: boolean;
  listStyle?: string;
  activeItemIndex?: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onListOpen?: () => void;
  onListClose?: () => void;
  shortPhrase: boolean;
}

const ContractList: React.FC<ContractListProps> = ({
  contracts,
  isLoading,
  listStyle,
  activeItemIndex,
  onMouseEnter,
  onMouseLeave,
  onListOpen,
  onListClose,
  shortPhrase
}) => {
  const classes = ContractSearcherStyles();
  const history = useHistory();

  useEffect(() => {
    onListOpen && onListOpen();
    return () => {
      onListClose && onListClose();
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById(`contract-${activeItemIndex}`);
    const parentElement = element?.parentElement;

    if (parentElement && element) {
      parentElement.scrollTop = element.offsetTop - 40;
    }
  }, [activeItemIndex]);

  const redirectToContract = (contract: IContract) => {
    gaEventViewDetail(contract.id, contract.name, "smart_search")
    history.push(`/verejne-zakazky/${contract.slug}`);
  }


  const renderInner = () => {
    if (shortPhrase) {
      return <li className={classes.noItem}>Zadejte minimálně 2 znaky</li>;
    }
    if (isLoading) {
      return <li className={classes.noItem}>Loading...</li>;
    }
    if (contracts.length === 0) {
      return <li className={classes.noItem}>Nenalezeny žádné zakázky</li>;
    }
    return contracts.map((contract, index) => (
      <li
        key={index}
        id={`contract-${index}`}
        tabIndex={index}
        className={`${classes.listItem}${activeItemIndex === index ? ` ${classes.activeListItem}` : ""}`}
        onClick={() => redirectToContract(contract)}
      >
        {contract.name}
      </li>
    ));

  };

  return (
    <ul
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      className={listStyle || classes.popUpList}
      onKeyDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {renderInner()}
    </ul>
  );
};

export default ContractList;
