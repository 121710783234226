import React from "react";
import { Box, Typography } from "@material-ui/core";

interface iInfoBox {
  text: string;
}

const InfoBox = ({ text }: iInfoBox) => {

  return (
    <Box style={{ padding: "50px 20px", textAlign: "center", width: "100%" }}>
      <Typography component="p"
        style={{ fontSize: "2rem", fontWeight: 700, lineHeight: 1.75, color: "rgba(0, 0, 0, 0.30)" }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default InfoBox;