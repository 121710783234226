import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFilter, setPhrase } from "../../redux/global/global";
import { setPage } from "../../redux/contractCatalogue/contractCatalogue";
import Home from "./Home";
import { usePageViewMeta } from "../../hooks/usePageViewMeta";
import { NEWFILTER } from "../../redux/filters/filters";

const HomeContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { setPageMeta } = usePageViewMeta();
  useEffect(() => {
    setPageMeta(
      "Všechny veřejné zakázky na jednom místě | Portál Dodavatele",
      "Všechny veřejné zakázky na jednom místě. Zasílání zakázek na míru, okamžité podání nabídky a komunikace se zadavateli.",
      ["veřejná zakázka", "veřejné zakázky", "zadavatelé", "dodavatelé", "QCM", "portál", "výběrové řízení", "podo", "fen", "tendr", "cdd", "NEN", "tender arena", "EZAK"]
    );
    dispatch(setPhrase(""));
    dispatch(setPage(1));
    dispatch(setFilter(NEWFILTER));
  }, []);

  return <Home />;
};

export default HomeContainer;
