import { createStyles, makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../theme/theme";
import bg from "../../assets/bg.webp";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%"
    },
    hpWrap: {
      color: COLORS.LIGHTGREY
    },
    topWrap: {
      background: `url(${bg}) no-repeat center`,
      backgroundSize: "cover",
      width: "auto",
      height: "auto",
      textAlign: "center",
      paddingTop: 80,
      paddingBottom: 0,
      [theme.breakpoints.down("xs")]: {
        padding: "50px 0"
      }
    },
    introBlock: {
      margin: "0 auto",
      maxWidth: 800
    },
    bottomWrap: {
      background: COLORS.BGBLUE,
      overflow: "hidden"
    },
    intro1: {
      fontSize: 22,
      lineHeight: "33px",
      fontWeight: 700,
      textTransform: "uppercase",
      color: COLORS.GOLD,
      marginBottom: 15,
      [theme.breakpoints.down("xs")]: {
        fontSize: 28,
        lineHeight: "35px"
      }
    },
    intro2: {
      fontWeight: 800,
      fontSize: 50,
      lineHeight: "65px",
      textTransform: "uppercase",
      color: "#fff",
      marginBottom: 35,
      marginBlockStart: 0,
      [theme.breakpoints.down("xs")]: {
        fontSize: 41,
        marginBottom: 45
      }
    },
    intro3: {
      fontSize: 21,
      lineHeight: "26px",
      color: "#fff",
      marginTop: 45,
      marginBottom: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    hr: {
      height: 1,
      borderWidth: 0,
      backgroundColor: "#545454",
      margin: 0
    },
    introWrapper: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row"
      },
      justifyContent: "space-around",
      marginBottom: 30
    },
    introSectionWrapper: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column"
      }
    },
    chooseCategoryButton: {
      color: "#fff",
      fontSize: 16
    },
    modalLink: {
      cursor: "pointer",
      fontSize: "0.875rem"
    },
    modalLinkIcon: {
      fontSize: 20,
      marginRight: 10,
      marginBottom: -2
    },
    introLinkWrap: {
      paddingTop: 40,
      paddingBottom: 80,
      display: "flex",
      justifyContent: "center"
    },
    linkWrap: {
      paddingTop: 60,
      paddingBottom: 80,
      display: "flex",
      justifyContent: "center"
    },
    introTitle: {
      display: "flex",
      flexDirection: "column",
      fontSize: 18,
      fontWeight: 300,
      lineHeight: "22px",
      color: "#fff",
      marginBottom: 2
    },
    introTitleIcon: {
      marginRight: 8,
      fontSize: 20,
      marginBottom: 15
    },
    introSubTitle: {
      fontSize: 16,
      fontWeight: 400,
      textAlign: "center",
    },
    podoArguments: {
      [theme.breakpoints.up("md")]: {
        paddingBottom: 100
      }
    },
    hpTitle: {
      fontWeight: 800,
      fontSize: 36,
      lineHeight: "40px",
      textAlign: "center",
      color: "#fff",
      marginBottom: 60
    },
    text: {
      display: "flex",
      flexDirection: "column"
    },
    hpNews:{
      padding: '60px 0',
      background: COLORS.BGBLUE,
    }
  })
);

export default useStyles;
