import React from "react";
import { Menu, Button, List, ListItem, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/root-reducer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Person from "@material-ui/icons/PersonOutline";
import useStyles from "./HeaderUserbox.styles";
import globalStyles from "../../../theme/global.styles";
import { selectUserName } from "../../../redux/user/selectors";
import { logOutAction } from "../../../redux/user/actions";

const HeaderUserbox: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { user } = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalClasses = globalStyles();

  const userName = useSelector(selectUserName);
  const MAX_LEN = 20;
  const userNameShort = userName.length > MAX_LEN ? userName.slice(0, MAX_LEN - 3) + "..." : userName;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    handleClose();
    dispatch(logOutAction());
    history.push("/");
  };

  return (
    <>
      <Button
        onClick={handleClick}
        className={classes.item}
        startIcon={<Person color={"primary"} />}
        endIcon={<ExpandMoreIcon />}
      >
        <div className={`${globalClasses.fontWeightBold}`}>
          {userNameShort}
        </div>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
      >
        <div>
          <List disablePadding>
            <div className={`${globalClasses.textCenter} ${globalClasses.pl3} ${globalClasses.pr3} ${classes.header}`}>
              <div className={`${globalClasses.fontWeightBold} ${globalClasses.colorPrimary}`}
                style={{backgroundColor: "transparent"}}>
                {userName}
              </div>
              <span className={`${globalClasses.textBlack50}`}>{user?.activeSubscription.level}</span>
            </div>
            <Divider />
            <ListItem
              button
              onClick={() => {
                handleClose();
                history.push("/profil");
              }}
            >
              Můj účet
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleLogOut()}>
              Odhlásit se
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

export default HeaderUserbox;
