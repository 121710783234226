import { makeStyles } from "@material-ui/core";
import { COLORS, theme } from "../../theme/theme";

const CategoryPickerModalStyles = makeStyles(() => ({
  modalTitleWrapper: {
    padding: "30px 0",
    textAlign: "center"
  },
  modalTitle: {
    fontWeight: 900
  },
  paper: {
    width: "70%",
    height: "65%",
    overflow: "auto",
    padding: "20px 50px",
    "&:focus": {
      outline: "none"
    }
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  category: {
    position: "relative",
    width: "33.3%",
    cursor: "pointer",
    overflowWrap: "anywhere",
    paddingRight: 20,
    marginBottom: 3,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: "50%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  categoryLink: {
    textDecoration: "none",
    lineHeight: 1,
    "&:hover": {
      color: `${COLORS.RED} !important`
    }
  }
}));

export default CategoryPickerModalStyles;
