import React, { useEffect } from "react";
import { Tag } from "../../../typings/contract";
import ContractSearcherStyles from "../ContractSearcher.styles";

interface TagListProps {
  tags: Array<Tag>;
  listStyle?: string;
  activeItemIndex?: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onListOpen?: () => void;
  onListClose?: () => void;
  onTagSelect: (index: number) => void;
}

const TagList: React.FC<TagListProps> = ({
  tags,
  listStyle,
  activeItemIndex,
  onMouseEnter,
  onMouseLeave,
  onListOpen,
  onListClose,
  onTagSelect
}) => {
  const classes = ContractSearcherStyles();

  useEffect(() => {
    onListOpen && onListOpen();
    return () => {
      onListClose && onListClose();
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById(`tag-${activeItemIndex}`);
    const parentElement = element?.parentElement;

    if (parentElement && element) {
      parentElement.scrollTop = element.offsetTop - 40;
    }
  }, [activeItemIndex]);

  return (
    <ul
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      className={listStyle || classes.popUpList}
      onKeyDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {tags.length > 0 ?
        tags
          .map((tag, index) => (
            <li
              key={index}
              id={`tag-${index}`}
              tabIndex={index}
              className={`${classes.listItem}${activeItemIndex === index ? ` ${classes.activeListItem}` : ""}`}
              onClick={() => onTagSelect(index)}
            >
              #{tag.code} - {tag.label}
            </li>
          ))
        : (
          <li className={classes.noItem}>Nenalezeny žádné tagy</li>
        )}
    </ul>
  );
};

export default TagList;
