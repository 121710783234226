import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { Tag } from "../../typings/contract";
import CategoryPickerModalStyles from "./CategoryPickerModal.styles";
import { gaEventClickTag, gaEventOpenTags } from "../../utils/ga";

interface CategoryPickerModalProps {
  tags: Array<Tag>;
  close: () => void;
}

const CategoryPickerModal: React.FC<CategoryPickerModalProps> = ({ tags, close }) => {
  const classes = CategoryPickerModalStyles();
  const [hoveredTagIndex, setHoveredTagIndex] = useState<number | null>(null);

  const handleHover = (_event: React.MouseEvent<HTMLAnchorElement>, index: number | null) => setHoveredTagIndex(index);

  const handleTagClick = (tag: string) => {
    gaEventClickTag(tag);
    close();
  };

  useEffect(() => {
    gaEventOpenTags();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.modalTitleWrapper}>
        <Typography variant="h4" className={classes.modalTitle}>
          Kategorie zakázek
        </Typography>
      </div>
      <div className={classes.wrapper}>
        {tags
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((tag, index) => (
            <div key={tag.code} className={classes.category}>
              <Link
                onClick={() => handleTagClick(tag.code)}
                onMouseEnter={(event) => handleHover(event, index)}
                onMouseLeave={(event) => handleHover(event, null)}
                to={`/verejne-zakazky?phrase=${encodeURIComponent(`#${tag.code}`)}`}
                className={classes.categoryLink}
              >
                {hoveredTagIndex === index && (
                  <FontAwesomeIcon icon={faCheck} style={{ position: "absolute", left: -5 }} />
                )}
                <div style={{ paddingLeft: 20 }}>{tag.label}</div>
              </Link>
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default CategoryPickerModal;
