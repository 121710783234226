enum SubscriptionPlan {
  FREE = "free",
  PROFI = "profi",
  PREMIUM = "premium",
  EXCELLENT = "excellent"
}

export enum SubscriptionType {
  RECURRING_MONTHLY = "RECURRING_MONTHLY",
  ANNUAL = "ANNUAL"
}

export enum SubscriptionPrice {
  FREE = 0,
  PROFI_RECURRING_MONTHLY = 400,
  PROFI_ANNUAL = 4000,
  PREMIUM_RECURRING_MONTHLY = 700,
  PREMIUM_ANNUAL = 7000,
  EXCELLENT_RECURRING_MONTHLY = 1000,
  EXCELLENT_ANNUAL = 10000,
}

export const BOLD_INDEX = [13, 14, 20, 21, 22];

export const PlanFeatures = [
  ["Vyhledávání zakázek malého rozsahu", true, true, true, true],
  ["Vyhledávání podlimitních zakázek", true, true, true, true],
  ["Vyhledávání nadlimitních zakázek", true, true, true, true],
  ["Zobrazení kompletního katalogu zakázek", true, true, true, true],
  ["Zobrazení úplného detailu aktivních zakázek", true, true, true, true],
  ["Pokročilé vyhledávání všech zakázek", true, true, true, true],
  ["Počet oborů veřejných zakázek - 1000+", true, true, true, true],
  ["Virtuální profily zadavatelů s přehledem všech jejich zakázek", true, true, true, true],
  ["Dokumenty u aktivních zakázek", false, true, true, true],
  ["Sledování označených zakázek", false, "5", "20", "40"],
  ["Ukládání vyhledávacích profilů (filtrů)", false, "3", "10", "25"],
  ["Nové zakázky dle nastavených filtrů - denně", false, true, true, true],
  ["Změny u sledovaných zakázek - denně", false, true, true, true],
  ["eKomunikace - přehled zpráv z elektronických nástrojů", false, true, true, true],
  ["Mobilní aplikace - PUSH notifikace", false, true, true, true],
  ["Nové zakázky dle nastavených filtrů - okamžitě", false, false, true, true],
  ["Změny u sledovaných zakázek - okamžitě", false, false, true, true],
  [
    "Detaily neaktivních zakázek - úplné informace u všech veřejně dostupných zakázek vypsaných podle zákona",
    false,
    false,
    true,
    true
  ],
  ["Filtrování jen aktivních zakázek v katalogu", false, false, true, true],
  ["Dokumenty u neaktivních zakázek", false, false, true, true],
  ["Rozšířený reporting dalších parametrů zakázky", false, false, false, "formát xlsx"],
  ["Notifikace k termínu podání nabídky", false, false, false, true],
  ["eKomunikace - podání nabídek přímo v Portálu dodavatele", false, false, true, true],
  ["Negativní klíčová slova", false, false, false, true]
];

export default SubscriptionPlan;
