import { useSelector } from "react-redux";
import { Route as RouterRoute, RouteProps as ReactRouteProps } from "react-router-dom";
import React from "react";
import { selectUser, selectUserInit } from "../../redux/user/selectors";
import LoadingBox from "../LoadingBox/LoadingBox";
import LockedOverlayWrapStyles from "../LockedOverlay/LockedOverlayWrap.styles";
import { useHandleLogin } from "../../hooks/useHandleLogin";
import Button from "@material-ui/core/Button";
import globalStyles from "../../theme/global.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export interface RouteProps extends ReactRouteProps {
  loginRequired?: boolean;
}

const Route: React.FC<RouteProps> = ({ loginRequired = false, ...otherProps }) => {
  const globalClasses = globalStyles();
  const classes = LockedOverlayWrapStyles();
  const user = useSelector(selectUser);
  const isInit = useSelector(selectUserInit);
  const { handleLogin } = useHandleLogin();

  if (!isInit) {
    return <LoadingBox />;
  }

  if (loginRequired && !user) {
    return (
      <div className={classes.paper}>
        <h2 id="transition-modal-title">Pro tuto funkcionalitu se musíte přihlásit.</h2>
        <div id="transition-modal-description">
          <div>
            <div style={{ paddingBottom: 30 }}>Pro využití této funkcionality se musíte přihlásit pomocí CDD.</div>
            <div>
              <Button className={globalClasses.btnSecondary} onClick={handleLogin}>
                Přihlásit se
                <FontAwesomeIcon className={globalClasses.btnIcon} icon={faArrowRight} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <RouterRoute {...otherProps} />;
};

export default Route;
