const prepareStats = (value: number ) => {
  let legend: string;

  if (value >= 1000000000000) {
    legend = "bil.";
    value = parseFloat((value / 1000000000000).toFixed(2));
  } else if (value >= 1000000000) {
    legend = "mld.";
    value = parseFloat((value / 1000000000).toFixed(1));
  } else if (value >= 1000000) {
    legend = "mil.";
    value = parseFloat((value / 1000000).toFixed(1));
  } else if (value >= 1000) {
    legend = "tis.";
    value = parseFloat((value / 1000).toFixed(1));
  } else {
    legend = "";
  }

  return { legend, value };
}

export const processData = (data: {[key: string]: number}) => {
  const result: { [key: string]: {legend: string, value: number} } = {};

  Object.keys(data).forEach((key) => {
    result[key] = prepareStats(data[key]);
  });

  return result;
};