export const substrToNextWhitespace = (string: string, length: number) => {
  for (let i = length; i <= string.length; i++) {
    const character = string.charAt(i);

    if (/\s/.test(character)) return string.substr(0, i);
  }

  return string;
};

export const firstStringDifference = (comparedString: string, source: string) => {
  const comparedStringSplit = comparedString.split(" ");
  const sourceStringSplit = source.split(" ");

  return sourceStringSplit
    .map((string) => (comparedStringSplit.includes(string) ? undefined : string))
    .filter((string) => string !== undefined);
};

export const currentString = (string: string, position: number | undefined) => {
  const start = string.slice(0, position);
  const end = string.slice(position, string.length);

  let startOfOurWord = start,
    endOfOurWord = end;

  const firstSpaceToLeft = start.lastIndexOf(" ");
  const firstSpaceToRight = end.indexOf(" ");

  if (firstSpaceToLeft !== -1) {
    startOfOurWord = start.slice(firstSpaceToLeft + 1, position);
  }

  if (firstSpaceToRight !== -1) {
    endOfOurWord = end.slice(0, firstSpaceToRight);
  }

  return `${startOfOurWord}${endOfOurWord}`;
};
