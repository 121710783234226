import { useKeycloak } from "@react-keycloak/web";

export const useKeycloakActions = () => {
  const { keycloak } = useKeycloak();

  const keycloakLogin = (redirectUri: string) => {
    keycloak.login({ redirectUri: redirectUri, scope: "offline_access" });
  };

  const keycloakLogout = (redirectUri: string) => {
    keycloak.logout({ redirectUri: redirectUri });
  };

  const keycloakCreateLoginUrl = (redirectUri: string) => {
    return keycloak.createLoginUrl({ redirectUri: redirectUri, scope: "offline_access" });
  };

  return { keycloakLogin, keycloakLogout, keycloakCreateLoginUrl };
};