import Cookies from "js-cookie";

const COOKIE_KEY = "podo-cookie-consent";
const COOKIE_STATE_ACCEPT = "accept";
const COOKIE_STATE_FORBIT = "forbit";

const getCookieConsent = () =>
  Cookies.get(COOKIE_KEY);

export const isCokieConsentAccept = () =>
  getCookieConsent() === COOKIE_STATE_ACCEPT;

const isCokieConsentForbit = () =>
  getCookieConsent() === COOKIE_STATE_FORBIT;

const setCookieConsent = (state: string) =>
  Cookies.set(COOKIE_KEY, state, { expires: 365, sameSite: "strict" });


export const setCookieConsentAccept = () =>
  setCookieConsent(COOKIE_STATE_ACCEPT);


export const setCookieConsentForbit = () =>
  setCookieConsent(COOKIE_STATE_FORBIT);

export const isSetCookieConsent = () =>
  isCokieConsentAccept() || isCokieConsentForbit();