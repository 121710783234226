import { makeStyles } from "@material-ui/core/styles";
import {COLORS} from "../../../theme/theme";

const useStyles = makeStyles(() => ({
  header: {
    padding: "1rem"
  },
  item: {
    color: COLORS.BGBLUEDARK,
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "none",
    paddingLeft: 20,
    marginLeft: 15,
    borderRadius: 0,
    borderLeft: "1px solid #B4B4B4",
    transition: "color 0.25s",
    "&:hover": {
      color: COLORS.RED,
      backgroundColor: "transparent"
    }
  },
}));

export default useStyles;
