export enum SortField {
  BEGINS = "beginsAt",
  NAME = "name",
  SUBMITTER = "submitter.name",
  CREATEAT = "createAt"
}

export enum SortOrder {
  DESC = "desc",
  ASC = "asc"
}

export enum SortFieldSubmitter {
  CONTRACTS_COUNT = "contractsCount",
  CONTRACTS_COUNT_MONTH = "contractsCountLastMonth",
  NAME = "name"
}