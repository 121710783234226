import { makeStyles } from "@material-ui/core/styles";

const BreadcrumbNavigationStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    paddingBottom: 20
  },
  title: {
    fontSize: 12,
    color: "rgba(0, 0, 0, .75)",
    paddingRight: 15
  }
}));

export default BreadcrumbNavigationStyles;
