import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Submitter } from "../../typings/submitter";
import { SortFieldSubmitter, SortOrder } from "../../enum/sort";

interface State {
  submitters: {
    data: Array<Submitter> | null;
    results: number;
  };
  page: number;
  rowsPerPage: number;
  sort: {
    field: string;
    order: string;
  };
  status: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
}

export const INITIAL_STATE: State = {
  submitters: {
    data: null,
    results: 0
  },
  sort: {
    field: SortFieldSubmitter.CONTRACTS_COUNT,
    order: SortOrder.DESC
  },
  page: 1,
  rowsPerPage: 20,
  status: {
    loading: false,
    error: false,
    success: false
  }
};

const submitterCatalogueSlice = createSlice({
  name: "submitterCatalogue",
  initialState: INITIAL_STATE,
  reducers: {
    changePage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    changeRowsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.rowsPerPage = payload;
    },
    searchSubmittersStart: (state) => {
      state.status.loading = true;
    },
    searchSubmittersSuccess: (state, { payload }: PayloadAction<{ data: Array<Submitter>; results: number }>) => {
      state.status.loading = false;
      state.status.success = true;
      state.submitters = payload;
    },
    searchSubmittersFailure: (state) => {
      state.status.loading = false;
      state.status.error = true;
    },
    setSortField: (state, action) => {
      state.sort.field = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sort.order = action.payload;
    },
    clearSort: (state) => {
      state.sort = {
        field: "",
        order: ""
      };
    }
  }
});

const { actions, reducer } = submitterCatalogueSlice;
export const {
  changePage,
  changeRowsPerPage,
  searchSubmittersStart,
  searchSubmittersSuccess,
  searchSubmittersFailure,
  setSortField,
  setSortOrder,
  clearSort
} = actions;

export default reducer;
