import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProfileState {
  status: {
    loading: boolean;
    error: boolean;
    success: boolean;
    message: string;
  };
}

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    status: {
      loading: false,
      error: false,
      success: false,
      message: ""
    }
  } as ProfileState,
  reducers: {
    sendFeedbackStart: (state) => {
      state.status.loading = true;
    },
    sendFeedbackSuccess: (state) => {
      state.status.loading = false;
      state.status.success = true;
    },
    sendFeedbackFailure: (state, action: PayloadAction<string>) => {
      state.status.loading = false;
      state.status.error = true;
      state.status.message = action.payload;
    },
    clearStatus: (state) => {
      state.status = {
        loading: false,
        error: false,
        success: false,
        message: ""
      };
    }
  }
});

const { actions, reducer } = profileSlice;
export const {
  sendFeedbackStart,
  sendFeedbackSuccess,
  sendFeedbackFailure,
  clearStatus
} = actions;
export default reducer;
