import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/root-reducer";
import Captcha from "./Captcha";
import { setSecureBlock } from "../../redux/global/global";
import { promiseSecureDefered } from "../../api/interceptorResponseSecureCaptcha";
import { useHandleLogin } from "../../hooks/useHandleLogin";
import { COLORS } from "../../theme/theme";

interface iCaptchaWrap {
  children: React.ReactNode;
}

const CaptchaWrap = ({ children }: iCaptchaWrap) => {

  const { global: { secureBlock } } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const onVerify = () => {
    promiseSecureDefered?.resolve();
    dispatch(setSecureBlock(false));
  };

  const { handleLogin } = useHandleLogin();

  if (secureBlock) {
    return <Captcha
      onVerify={onVerify}
      infoMsg={
        <div style={{ color: COLORS.RED, fontSize: "1.2em", fontWeight: "bold" }}>Nechcete opisovat captcha kódy?
          <br />
          Řešení je snadné - <a href={`${process.env.REACT_APP_CDD_URL}/#/registrace`}>registrujte
            se</a>, nebo se <a href="#login" onClick={handleLogin}>přihlaste</a>.
          <br />
          Přihlášené uživatele touto ochranou neobtěžujeme a budete moci nadále nerušeně využívat Portál Dodavatele
          naplno!
          <br />
          <br />
        </div>
      }
    >
      {null}
    </Captcha>;
  }

  return (<>
    {/*<button style={{ marginTop: 80 }} onClick={() => {*/}
    {/*  for (let i = 0; i < 10; i++) {*/}
    {/*    PodoApi.getContracts();*/}
    {/*  }*/}
    {/*}}>10 request*/}
    {/*</button>*/}
    {children}
  </>);
};

export default CaptchaWrap;
