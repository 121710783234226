import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  draw: {
    padding: "50px 10px",
    "& *": {
      textDecoration: "none"
    }
  }
}));

export default useStyles;
