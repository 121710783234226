import React, { useState } from "react";
import { AppBar, Toolbar, Hidden, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import DesktopNavigation from "../Navigation/DesktopNavigation";
import MobileNavigation from "../Navigation/MobileNavigation";
import useStyles from "./Header.styles";
import Logo from "../../../assets/logonew.png";
import { MobileView } from "react-device-detect";

const MAINTENANCE_TEXT = process.env.REACT_APP_MAINTENANCE_TEXT;
const MAINTENANCE_SHOW = process.env.REACT_APP_MAINTENANCE_SHOW;
const infoBar = MAINTENANCE_TEXT && MAINTENANCE_SHOW && (MAINTENANCE_SHOW === '1' || MAINTENANCE_SHOW === 'true')

const Header: React.FC = () => {
  const classes = useStyles();

  const [showMobileAppInfo, setShowMobileAppInfo] = useState(true);

  return (
    <>
      <AppBar className={classes.appBar} elevation={0}>
        <Container maxWidth="lg">
          <Toolbar style={{justifyContent: "space-between", padding: 0}}>
            <div className={classes.logos}>
              <Link to="/" className={classes.logoLink} title={"Portál Dodavatele"}>
                <img alt={"PoDo logo"} src={Logo} width="100%" height="100%" className={classes.logo}/>
              </Link>
            </div>
            <Hidden mdDown>
              <DesktopNavigation />
            </Hidden>
            <Hidden lgUp>
              <MobileNavigation />
            </Hidden>
          </Toolbar>
        </Container>
        <MobileView>
          {showMobileAppInfo &&
            <div className={classes.maintenanceInfo + " " + classes.useMobileAppInfo}>
              Doporučujeme použít <a href="https://cdd.fen.cz/#/mobilni-aplikace">mobilní aplikaci</a>
              <span onClick={() => setShowMobileAppInfo(false)}>&times;</span>
            </div>
          }
        </MobileView>
        {infoBar &&
          <div className={classes.maintenanceInfo}>
            {MAINTENANCE_TEXT}
          </div>
        }
      </AppBar>
    </>
  );
};

export default Header;
