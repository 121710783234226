export class Security {

  static charsMap = [
    [
      ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'], // source
      ['3', 'c', '2', 'e', '0', '5', '.', '7', 'b', 'f', '8', 'd', '9', '1', '6', '4', 'a'], // goal
    ],
    [
      ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'], // source
      ['h', 'd', 'f', 'k', 'a', 'l', 'o', 'v', 'b', 'u', 'e', 'r', 'q', 'c', 'p', 'w', 'y'], // goal
    ]
  ];


  static o(text: string, partLength = 4, mapIndex = 0) {
    if (partLength === 0) {
      return this.rChars(text.split('').reverse().join(''), mapIndex);
    }

    const pattern = ".{1," + partLength + "}";
    const reg = new RegExp(pattern, "g");
    const parts = text.match(reg);
    const betterParts = [];

    if (parts) {
      for (let i = parts.length; i > 0; i--) {
        betterParts.push(parts[i - 1] + parts[parts.length - i]);
        i--;
      }
    }

    return this.rChars(betterParts.join("."), mapIndex);
  }

  static rChars(text: string, mapIndex: number) {

    const sourceChars = this.charsMap[mapIndex][0]
    const goalChars = this.charsMap[mapIndex][1]

    const chars: string[] = [];

    text.split("").forEach((item) => {
      chars.push(goalChars[sourceChars.indexOf(item)]);
    });

    return chars.join("");
  }
}
