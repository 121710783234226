import React from "react";
import { Modal, Paper } from "@material-ui/core";
import useContractTags from "../../hooks/useContractTags";
import globalStyles from "../../theme/global.styles";
import CategoryPickerModalStyles from "./CategoryPickerModal.styles";
import CategoryPickerModal from "./CategoryPickerModal";

interface iCategoryPickerModalWrap {
  open: boolean,
  setOpen: (open: boolean) => void
}

const CategoryPickerModalWrap = ({ open, setOpen }: iCategoryPickerModalWrap) => {

  const globalClasses = globalStyles();
  const pickerClasses = CategoryPickerModalStyles();
  const { isLoading, tags } = useContractTags();
  const handleCloseModal = () => setOpen(false);

  return (
    <Modal id="modal-tags" open={open} onClose={handleCloseModal}>
      <Paper className={`${globalClasses.center} ${pickerClasses.paper}`}>
        {isLoading ? "Loading" : <CategoryPickerModal tags={tags} close={handleCloseModal} />}
      </Paper>
    </Modal>
  );
};

export default CategoryPickerModalWrap;
