import { makeStyles } from "@material-ui/core/styles";
import {COLORS} from "../../theme/theme";

const NewsListStyles = makeStyles(() => ({
  article: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  articleTitleHp: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "22px",
    color: "#fff !important",
    transition: "0.25s",
    "&:hover": {
      color: `${COLORS.RED} !important`
    }
  },
  articleTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "22px",
    color: "#aa8e6c",
    transition: "0.25s",
    "&:hover": {
      color: `${COLORS.DARKGOLD} !important`
    }
  },
  articleTextHp: {
    color: "#fff",
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 400,
    paddingTop: 10,
    "& > p > a": {
      color: "#fff !important",
      transition: "0.25s",
      "&:hover": {
        color: `${COLORS.RED} !important`
      }
    }
  },
  articleText: {
    color: "#0c1c27",
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 400,
    paddingTop: 10,
    "& > p > a": {
      color: "#0c1c27",
      transition: "0.25s",
      "&:hover": {
        color: `${COLORS.DARKGOLD} !important`
      }
    }
  },
  articleLinkHp: {
    color: "#fff",
    fontWeight: 700,
    paddingLeft: 0,
    transition: "0.25s",
    "&:hover": {
      backgroundColor: "transparent",
      color: `${COLORS.RED} !important`
    },
  },
  articleLink: {
    color: "#aa8e6c",
    fontWeight: 700,
    paddingLeft: 0,
    transition: "0.25s",
    "&:hover": {
      backgroundColor: "transparent",
      color: `${COLORS.DARKGOLD} !important`
    }
  },
}));

export default NewsListStyles;