import { createStyles, makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme/theme";

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      position: "fixed",
      background: "white",
      bottom: 0,
      right: 0,
      left: 0,
      padding: "15px 20px",
      fontSize: 13
    },
    container: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row"
      }
    },
    text: {
      maxWidth: 860,
      marginRight: 40

    },
    button: {
      textTransform: "none",
      margin: "0 5px",
      fontSize: 13,
      minWidth: 130,
      marginTop: 15,
      [theme.breakpoints.up("md")]: {
        marginTop: 0
      }
    }
  })
);

export default useStyles;
