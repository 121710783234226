import { makeStyles } from "@material-ui/core/styles";
import {COLORS, theme} from "../../theme/theme";

const StatisticsStyles = makeStyles(() => ({
  wrapper: {
    background: COLORS.BGBLUE,
    padding: "70px 10%"
  },
  midTitle: {
    fontSize: 28,
    fontWeight: 300,
    paddingBottom: 40
  },
  subtitle: {
    marginBlockEnd: 5
  },
  chartWrapper: {
    width: "100%",
    margin: "0 auto",
    marginBottom: 35
  },
  chartSectionWrapperWide: {
    maxWidth: 1280,
    margin: "0 auto",
    display: "grid",
    gridTemplateColumns: "1fr 3fr 1fr",
    gap: "1rem",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "3fr 1fr"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr"
    }
  },
  cardWrapper: {
    maxWidth: 1280,
    margin: "0 auto"
  },
  onlyLarge: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-end",
    textAlign: "center",
    columnGap: "0.5rem",
    marginBottom: "1rem"
  },
  card: {
    textAlign: "center",
    background: COLORS.BGBLUE,
    boxShadow: "none"
  },
  cardLight: {
    textAlign: "center",
    background: "#fff",
    boxShadow: "none"
  },
  cardIcon: {
    height: 65,
    margin: "0 auto",
    border: "none",
    color: COLORS.GOLD
  },
  textTitle: {
    color: "#fff",
    fontSize: 36,
    lineHeight: "40px",
    fontWeight: 700,
    marginBottom: 10
  },
  text: {
    color: "#fff",
    fontWeight: 300,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "baseline",
    textAlign: "center",
    columnGap: "0.5rem",
    fontSize: 18,
    lineHeight: "22px"
  },
  textTitleLight: {
    color: COLORS.BLUE,
    fontSize: 36,
    lineHeight: "40px",
    fontWeight: 700,
    marginBottom: 10
  },
  textLight: {
    color: COLORS.BLUE,
    fontWeight: 300,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "baseline",
    textAlign: "center",
    columnGap: "0.5rem",
    fontSize: 18,
    lineHeight: "22px"
  },
  descWrapper: {
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  }
}));

export default StatisticsStyles;
