import { useState } from "react";

const useLocalStorage = <T>(key: string, initialValue: any): [T, (value: any) => void] => {

  const [storedValue, setStoredValue] = useState(() => {
    const storedItem = window.localStorage.getItem(key);
    return storedItem ? JSON.parse(storedItem) : initialValue;
  });

  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (e: any) {
      console.log(e.message);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
