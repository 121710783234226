import ErrorResponseMessages, { ErrorCodeMap, ErrorContractMap } from "../typings/errorResponse";
import axios from "axios";


export const parseErrorSync = (errorResponse: any, defaultMsg: string = ErrorResponseMessages.GENERAL) => {

  if (errorResponse?.response?.status === 422 && errorResponse?.response?.data?.violations?.[0]?.code === "23bd9dbf-6b9b-41cd-a99e-4844bcf3077f") {
    return ErrorResponseMessages.FILTER_DUPLICATE_NAME;
  }

  if (axios.isAxiosError(errorResponse)) {
    const error = errorResponse.response?.data?.error;
    if (error?.code in ErrorCodeMap) {
      return ErrorCodeMap[error.code as keyof typeof ErrorCodeMap];
    }

    //TODO error hlasky - je detail v cestine ?
    // const detail = errorResponse.response?.data?.detail;
    // if (detail) {
    //   return detail;
    // }

    return defaultMsg;
  }

  return defaultMsg;
};

export const parseErrorContract = (errorResponse: any): string => {

  if (errorResponse?.response?.status === 404 && errorResponse.response?.data?.error.code === 1806) {
    return ErrorResponseMessages.CONTRACT_NOT_FOUND;
  }

  if (axios.isAxiosError(errorResponse)) {
    const error = errorResponse.response?.data?.error;
    if (error?.code in ErrorCodeMap) {
      return ErrorContractMap[error.code as keyof typeof ErrorContractMap];
    }

    return ErrorResponseMessages.GENERAL;
  }

  return ErrorResponseMessages.GENERAL;
};

