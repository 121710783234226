import React from "react";

const BasicSearchTooltip: React.FC = () => (
  <React.Fragment>
    <p>
      <b>Základní vyhledávání:</b>
    </p>
    <p>
      Pro vyhledání zakázky zadejte výraz do textového pole (s nebo bez diakritiky). Již při psaní výrazu dochází k
      okamžitému vyhledávání zakázek. Zakázky, které nejvíce odpovídají zadaným klíčovým slovům, se dynamicky zobrazuji
      v okamžité nabídce. Pro zobrazení detailu zakázky zobrazené v okamžité nabídce klikněte na zakázku myší(případně
      navigujte šipkami nahoru a dolů a potvrďte stiskem klávesy Enter).
    </p>
    <p>
      Seznam navržených zakázek je omezen na 5 kusů. Pokud chcete zobrazit více zakázek, potvrďte vyhledání zadaného
      výrazu kliknutím na tlačítko „Vyhledat zakázku“ nebo stiskem klávesy Enter. Budete následně přesměrováni na
      "Katalog zakázek" kde můžete využít naplno možností systému.
    </p>
    <p>Okamžité vyhledávání v PoDo nyní pracuje s informacemi a s váhou v následujících polích dle pořadí:</p>
    <p>
      <ul>
        <li>Název zakázky</li>
        <li>Název zadavatele</li>
        <li>Popis zakázky</li>
      </ul>
    </p>
    <p>
      Pokud je zadaný výraz nalezen v některém z těchto polí, je výsledku přiřazena váha (viz výše), pokud je výraz
      nalezen ve více polích současně, je váha výsledku stanovena jako součet vah všech polí, ve kterých byl výraz
      nalezen.
    </p>
    <p>
      Výsledný seznam nalezených zakázek je seřazen dle váhy sestupně. Pokud má více nalezených výsledků stejnou váhu,
      jsou seřazeny dle data zahájení zadávacího řízení sestupně.
    </p>
    <p>Pozn.:</p>
    <p>
      Zadaný výraz musí být v jednom z polí obsažený celý, tzn. při zadání výrazu „červené auto“ se vyhledají zakázky,
      které obsahují „červené“ a „auto“ v jednom z polí – např. zakázku s popisem „koupím auto, musí být červené“.
      Zakázka s popisem „koupím auto“ již nebude splňovat kritéria.
    </p>
  </React.Fragment>
);

export default BasicSearchTooltip;
