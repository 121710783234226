import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageWrap from "../../components/PageWrap/PageWrap";
import { gaPageView } from "../../utils/ga";

const NotFoundErrorPage = () => {
  const location = useLocation();
  const errorTitle = "404 stránka nenalezena";

  useEffect(() => {
    gaPageView(location.pathname + location.search, errorTitle);
  }, []);

  return (
    <PageWrap breadcrumbNavigation={[{ label: errorTitle }]} title={errorTitle}>
      Požadovaný dokument, stránka nebyl/a nalezen/a
    </PageWrap>
  );
};

export default NotFoundErrorPage;
