import { createStyles, makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../../theme/theme";

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    companiesWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: "1rem",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    companyImage: {
      height: "2.31rem",
      marginBottom: "0.7rem"
    },
    iconContainer: {
      display: "flex",
      alignContent: "center",
      marginBottom: 10
    },
    separator: {
      height: 1,
      marginBottom: "1.875rem",
      display: "block",
      background: "rgba(117,117,117,0.8)",
      width: "100%"
    },
    iconsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: 20,
      paddingTop: 20,
      paddingBottom: 20
    },
    logoFen:{
      height: 35,
      width: "auto",
      marginTop: 5,
      marginRight: 20,
      opacity: 0.25,
      transition: "opacity 0.25s",
      "&:hover": {
        opacity: 0.5
      }
    },
    socialIcon: {
      marginTop: 4,
      color: "#fff !important",
      "&:hover": {
        color: `${COLORS.RED} !important`
      }
    },
    footer: {
      width: "100%",
      background: COLORS.BGBLUEDARK,
      color: "white",
      fontWeight: 300,
      padding: "2.5rem 0 1.5rem",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      }
    },
    gridWrapper: {
      justifyContent: "space-between"
    },
    footerCol: {
      width: "25%",
      paddingRight: 10,
      [theme.breakpoints.down(960)]: {
        width: "50%",
        marginBottom: 40
      },
      [theme.breakpoints.down(540)]: {
        width: "100%",
        marginBottom: 40
      }
    },
    title: {
      fontSize: 18,
      marginBottom: 20,
      fontWeight: 500
    },
    footerText: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "18px"
    },
    contact: {
      marginTop: 15
    },
    icon: {
      paddingRight: 10,
      fontSize: 13
    },
    socialContainer: {
      paddingTop: 5,
      marginBottom: 25
    },
    link: {
      color: "#fff !important",
      "&:visited": {
        color: "#fff !important"
      },
      "&:hover": {
        color: `${COLORS.RED} !important`
      }
    },
    mobileAppLink: {
      fontSize: 14,
      fontWeight: 700,
      display:'block',
      marginBottom: 10
    },
    mobileAppDownload: {
      display: "flex",
      paddingTop: 20
    },
    mobileAppGooglePlay: {
      marginRight: 10
    },
    bottom: {
      borderTop: "1px solid #ffffff30",
      paddingTop: 20
    }
  })
);

export default useStyles;
