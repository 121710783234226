import { makeStyles } from "@material-ui/core/styles";
import {COLORS, theme} from "../../../../theme/theme";

const PodoArgumentsStyles = makeStyles(() => ({
  wrapper: {
    background: COLORS.BGBLUE,
    padding: "10px 10px 60px 10px"
  },
  midTitle: {
    fontWeight: 800,
    fontSize: 36,
    lineHeight: "40px",
    textAlign: "center",
    color: "#fff",
    marginBottom: 60
  },
  cardWrapper: {
    width: "100%",
    maxWidth: 1280,
    margin: "0 auto"
  },
  gridItem: {
    [theme.breakpoints.down(1052)]: {
      maxWidth: "50%",
      flexBasis: "50%"
    }
  },
  card: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: COLORS.BGBLUE,
    color: "#AA8E6C",
    overflow: "inherit",
    boxShadow: "none"
  },
  cardIcon: {
    height: 65,
    margin: "0 auto",
    border: "none",
    color: COLORS.GOLD
  },
  textTitle: {
    color: "#fff",
    fontSize: 21,
    lineHeight: "26px",
    fontWeight: 700,
    marginBottom: 20,
    [theme.breakpoints.up(1052)]: {
      minHeight: 104
    },
    [theme.breakpoints.up(1200)]: {
      minHeight: 78
    }
  },
  text: {
    color: "#fff",
    fontWeight: 400,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "baseline",
    textAlign: "center",
    columnGap: "0.5rem",
    fontSize: 16,
    lineHeight: "20px"
  }
}));

export default PodoArgumentsStyles;